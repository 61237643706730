import React from "react";
import { connect } from "react-redux";
import { Button, Grid, TextField } from "@material-ui/core";

import BackEnd from "../apis/caseBackEnd";
import {
	setAnswers,
	clearAnswers,
	setAnalysisTree,
	setMetrics,
	setAnswerAnalysis,
} from "../actions";
import history from "../history";

class AnswerBox extends React.Component {
	// DO NOT delete below state.
	// Required for controlling the answer boxes
	state = {
		answers: [],
	};
	// DO NOT delete above state

	fetchAnswer = async () => {
		return await BackEnd.post("/answer", {
			answer: this.props.answers,
			case: this.props.case,
			accountID: this.props.user.uid,
			time: this.props.getTime(),
		});
	};

	handleAnswer() {
		this.fetchAnswer()
			.then((response) => {
				const data = response.data;
				console.log(response);
				this.setState({
					answer: data["response"],
				});
				console.log(this.state.answer);
				this.props.setAnalysisTree(data["tree"]);
				this.props.setMetrics(data["metrics"]);

				this.props.setAnswers(this.props.answers);
				this.props.setAnswerAnalysis({
					ideal: data["idealAnswers"],
					qType: this.state.answer,
					user: this.props.answers,
					questions: this.props.questions,
				});
				history.push("/analysis");
			})
			.catch((e) => {});
	}

	handleAnswerChange(event, index) {
		event.preventDefault();
		const tempAnswers = this.props.answers;
		tempAnswers[index] = event.target.value;
		this.setState({
			answers: tempAnswers,
		});
		this.props.setAnswers(event.target.value, index);
	}

	render() {
		return (
			<>
				<div className="answer-container">
					{this.props.questions ? (
						this.props.questions.map((question, index) => (
							<Grid item container direction="column" key={index}>
								<Grid item>{`${index + 1}. ${question.question}`}</Grid>
								<Grid item>
									<label>{question.multi}</label>
									<TextField
										value={this.state.answers[index] || ""}
										variant="outlined"
										style={{
											marginTop: "1em",
											marginBottom: "2em",
											width: "100%",
										}}
										label={`Answer`}
										multiline={question.multi}
										rows={question.multi ? 3 : 1}
										onChange={(event) => {
											this.handleAnswerChange(event, index);
										}}
									/>
								</Grid>
							</Grid>
						))
					) : (
						<div>Loading Questions</div>
					)}
				</div>
				<Button
					id="answerButton"
					onClick={() => this.handleAnswer()}
					variant="outlined">
					Submit Answer
				</Button>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		questions: state.questions,
		answers: state.answers,
		case: state.case,
		accountID: state.accountID,
		user: state.user,
		startTime: state.startTime,
	};
};

export default connect(mapStateToProps, {
	setAnswers,
	clearAnswers,
	setAnalysisTree,
	setMetrics,
	setAnswerAnalysis,
})(AnswerBox);
