import React, { useState } from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Hidden } from "@material-ui/core";
import "../styles/landing.css";
import { Link } from "react-router-dom";
import { Grid, CssBaseline, Button, Typography } from "@material-ui/core";

import hero from "../assets/landingHero.jpg";
import lp1 from "../assets/lp1.png";
import lp2 from "../assets/lp2.png";
import Footer from "./Footer";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
    featureText: {
        fontFamily: "Montserrat",
        fontWeight: 600,
    },

    featureTitle: {
        fontFamily: "Montserrat",
        textTransform: "uppercase",
    },
    brandOff: {
        width: "15em",
        opacity: 0,
    },
    brandOn: {
        width: "15em",
    },
    root: {
        display: "flex",
    },
    appBar: {
        backgroundColor: "black",
        transition: ".2s",
    },

    appBarScroll: {
        backgroundColor: "white",
        transition: ".2s",
    },
    tabsWhite: {
        color: "#000000",
    },

    tabsBlack: {
        color: "#ffffff",
    },
    heroButton: {
        "&:hover": {
            backgroundColor: "#aaaaaa",
        },
    },

    heroVisible: {
        opacity: "1",
        transitionDuration: ".2s",
    },

    heroInvisible: {
        opacity: "0",
        marginLeft: "2em",
        transitionDuration: ".2s",
    },

    heroIncoming: {
        opacity: "0",
        marginLeft: "-2em",
        transitionDuration: ".2s",
    },
    heroText: {
        marginTop: "15em",
        marginBottom: "2em",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            marginTop: 0,
        },
    },
    solveButtonContainer: {
        marginTop: "4em",
        marginBottom: "10em",
        [theme.breakpoints.down("sm")]: { marginBottom: "0" },
    },
    landingRoot: {
        height: "100vh",
        width: "100%",
        zIndex: 1,
        [theme.breakpoints.down("sm")]: {
            height: "80vh",
        },
    },
}));

export default (props) => {
    const [heroIndex, setHeroIndex] = useState(0);
    React.useEffect(() => {
        const interval = setInterval(
            () => setHeroIndex((heroIndex) => heroIndex + 1),
            3000
        );
        // console.log(heroIndex);

        return () => clearInterval(interval);
    });
    const classes = useStyles();
    const theme = useTheme();

    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Grid container direction="column" style={{ overflowX: "hidden" }}>
            <CssBaseline />
            <Header user={props.user} />
            <Grid container className={classes.landingRoot}>
                <Grid
                    container
                    className="hero"
                    alignItems="center"
                    justify="space-around"
                >
                    <Hidden xsDown>
                        <Grid item style={{ minWidth: "20em", width: "40vw" }}>
                            <img
                                src={hero}
                                alt=""
                                style={{
                                    objectFit: "contain",
                                    maxWidth: "40vw",
                                    minWidth: "20em",
                                }}
                            />
                        </Grid>
                    </Hidden>

                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justify="right"
                        style={{
                            maxHeight: "50em",
                            minWidth: "20em",
                            width: "30vw",
                        }}
                    >
                        <Grid item className={classes.heroText}>
                            <Typography
                                variant="h1"
                                id="heroText"
                                style={{
                                    fontFamily: "Montserrat",
                                    fontWeight: 900,
                                    color: "#000",
                                    textAlign: "center",
                                    position: "absolute",
                                    left: 0,
                                }}
                                className={
                                    heroIndex % 3 === 0
                                        ? classes.heroVisible
                                        : heroIndex % 3 === 1
                                        ? classes.heroInvisible
                                        : classes.heroIncoming
                                }
                            >
                                Practice
                            </Typography>
                            <Typography
                                variant="h1"
                                id="heroText"
                                style={{
                                    fontFamily: "Montserrat",
                                    fontWeight: 900,
                                    color: "#000",

                                    textAlign: "center",
                                    position: "absolute",
                                    left: 0,
                                }}
                                className={
                                    heroIndex % 3 === 1
                                        ? classes.heroVisible
                                        : heroIndex % 3 === 2
                                        ? classes.heroInvisible
                                        : classes.heroIncoming
                                }
                            >
                                Learn
                            </Typography>
                            <Typography
                                variant="h1"
                                id="heroText"
                                style={{
                                    fontFamily: "Montserrat",
                                    fontWeight: 900,
                                    color: "#000",
                                    textAlign: "center",
                                    position: "absolute",
                                    left: 0,
                                }}
                                className={
                                    heroIndex % 3 === 2
                                        ? classes.heroVisible
                                        : heroIndex % 3 === 0
                                        ? classes.heroInvisible
                                        : classes.heroIncoming
                                }
                            >
                                Think
                            </Typography>
                        </Grid>
                        <Grid item className={classes.solveButtonContainer}>
                            <Button
                                className={classes.heroButton}
                                variant="contained"
                                style={{
                                    borderRadius: "20px",
                                    backgroundColor: "#ff4f5a",
                                }}
                                component={Link}
                                to="/login"
                            >
                                <Typography
                                    variant="h2"
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontWeight: 600,
                                        textTransform: "none",
                                        color: "white",
                                    }}
                                >
                                    Start Solving
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                direction="column"
                className="landingBackground"
                style={{ alignItems: "center" }}
            >
                <Grid
                    item
                    align="center"
                    style={{
                        marginTop: "20em",
                        maxWidth: "50em",
                        marginLeft: "5em",
                        marginRight: "5em",
                    }}
                >
                    <Grid container direction={matchesSM ? "column" : "row"}>
                        <Grid item container xs>
                            <img
                                src={lp1}
                                alt="Casecade on Monitor"
                                style={{
                                    width: "20em",
                                    marginLeft: "auto",
                                    marginRight: "auto",

                                    objectFit: "contain",
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            direction="column"
                            xs
                            justify="center"
                        >
                            <Grid
                                item
                                style={matchesSM ? { marginTop: "2em" } : {}}
                            >
                                <Typography
                                    variant="h2"
                                    className={classes.featureTitle}
                                    style={{
                                        fontWeight: 900,
                                    }}
                                >
                                    A New Way to Explore Cases
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    className={classes.featureText}
                                >
                                    Casecade is the only interactive platform to
                                    solve cases, enhance your thinking and
                                    prepare you for a career in consulting
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    align="center"
                    style={{
                        marginTop: "5em",
                        marginBottom: "5em",
                        maxWidth: "50em",
                        marginLeft: "5em",
                        marginRight: "5em",
                    }}
                >
                    <Grid
                        item
                        container
                        direction={matchesSM ? "column-reverse" : "row"}
                    >
                        <Grid
                            item
                            container
                            direction="column"
                            xs
                            justify="center"
                        >
                            <Grid item>
                                <Typography
                                    variant="h2"
                                    className={classes.featureTitle}
                                    style={{
                                        fontWeight: 900,
                                    }}
                                >
                                    Powered with AI
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    className={classes.featureText}
                                >
                                    Casecade is powered with top of the line
                                    Natural Language Processing to give you a
                                    smart interactive experience and polished
                                    feedback
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container xs>
                            <img
                                src={lp2}
                                alt="Brain"
                                style={{
                                    width: "20em",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    objectFit: "contain",
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </Grid>
    );
};
