import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "../styles/board.css";

export default function CustomizedMenus(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                aria-controls="customized-menu"
                disableRipple
                aria-haspopup="true"
                onClick={handleClick}
            >
                <Typography variant="h3" id="username">
                    {props.name}
                </Typography>
                <ArrowDropDownIcon
                    style={{ color: "white" }}
                    id={anchorEl ? "dropDownArrow" : "dropDownArrowUp"}
                />
            </Button>
            <Menu
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    id="signOutButton"
                    onClick={props.onClickSignOut}
                    style={{ borderRadius: 0 }}
                >
                    Sign Out
                </MenuItem>
            </Menu>
        </div>
    );
}
