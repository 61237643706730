import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
}));

export default function SimpleTabs(props) {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<TabPanel value={value} index={0}>
				{props.user.length === 0 ? (
					<Typography style={{ fontStyle: "italic", color: "#999" }}>
						You didn't ask for any information under this node!
					</Typography>
				) : (
					props.user.map((item, index) => (
						<Typography key={index}>{`${index + 1}. ${item}`}</Typography>
					))
				)}
			</TabPanel>
			<TabPanel value={value} index={1}>
				{props.ideal[0].map((item, index) => (
					<Typography key={index}>{`${index + 1}. ${item}`}</Typography>
				))}
			</TabPanel>
			<Tabs
				value={value}
				onChange={handleChange}
				aria-label="simple tabs example">
				<Tab label="Your Questions" {...a11yProps(0)} />
				<Tab label="Ideal Questions" {...a11yProps(1)} />
			</Tabs>
		</div>
	);
}
