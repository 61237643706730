import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import storage from "redux-persist/lib/storage";
import selectedCaseReducer from "./selectedCaseReducer";
import caseHeaderReducer from "./caseHeaderReducer";
import askValueReducer from "./askValueReducer";
import historyReducer from "./historyReducer";
import usernameReducer from "./usernameReducer";
import questionReducer from "./questionReducer";
import problemStatementReducer from "./problemStatementReducer";
import knowledgeReducer from "./knowledgeReducer";
import answerReducer from "./answerReducer";
import userReducer from "./userReducer";
import treeReducer from "./treeReducer";
import timeReducer from "./timeReducer";
import caseTitleReducer from "./caseTitleReducer";
import metricsReducer from "./metricsReducer";
import answerAnalysisReducer from "./answerAnalysisReducer";
import introFlagReducer from "./introFlagReducer";

const appReducer = combineReducers({
    case: selectedCaseReducer,
    headers: caseHeaderReducer,
    askValue: askValueReducer,
    form: formReducer,
    history: historyReducer,
    accountID: usernameReducer,
    problemStatement: problemStatementReducer,
    questions: questionReducer,
    knowledge: knowledgeReducer,
    answers: answerReducer,
    user: userReducer,
    analysisTree: treeReducer,
    startTime: timeReducer,
    caseTitle: caseTitleReducer,
    metrics: metricsReducer,
    answerAnalysis: answerAnalysisReducer,
    introFlag: introFlagReducer,
});

export default (state, action) => {
    if (action.type === "CLEAR_STORE") {
        const user = state.user;
        const headers = state.headers;
        storage.removeItem("persist:root");
        state = { user: user, headers: headers };
    }

    return appReducer(state, action);
};
