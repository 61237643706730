import React from "react";
import axios from "axios";

import "../styles/solution.css";
import { connect } from "react-redux";
import { CircularProgress, Grid, Typography } from "@material-ui/core";

class Blog extends React.Component {
    state = { blogPost: null, html: null };
    async fetchPosts() {
        await axios
            .get("https://blog.casecade.in/sol/wp-json/wp/v2/posts")
            .then(async (response) => {
                console.log(response);
                const post = await response.data.filter(
                    (post) => post.slug === this.props.case
                );
                console.log(post);
                this.setState({ blogPost: post[0].content.rendered });
            })
            .catch((e) => console.log(e));
    }

    createMarkup() {
        return { __html: this.state.blogPost };
    }

    componentDidMount() {
        this.fetchPosts();
    }

    render() {
        const data = this.createMarkup();
        if (!this.state.blogPost)
            return (
                <Grid container direction="column">
                    <Grid
                        item
                        container
                        justify="center"
                        style={{ marginTop: "10em" }}
                    >
                        <Typography id="topText">
                            Fetching Solution...
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <CircularProgress />
                    </Grid>
                </Grid>
            );
        return <div className="blog-post" dangerouslySetInnerHTML={data}></div>;
    }
}

const mapStateToProps = (state) => {
    return {
        case: state.case,
    };
};
export default connect(mapStateToProps)(Blog);
