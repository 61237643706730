import React from "react";
import { connect } from "react-redux";

import BookmarkIcon from "@material-ui/icons/Bookmark";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import FlagIcon from "@material-ui/icons/Flag";
import DeleteIcon from "@material-ui/icons/Delete";
import { modifyBookmarkedState, removeFromKnowledge } from "../actions";

import "../styles/case.css";
class KnowledgeBank extends React.Component {
	unbookmark(index) {
		this.props.removeFromKnowledge(index);
		this.props.modifyBookmarkedState(false, index);
	}

	iconInst = (
		<Grid container direction="column" style={{ marginTop: "3em" }}>
			<Grid container alignItems="center" justify="center">
				<Typography
					variant="body1"
					className="note-text"
					style={{
						fontFamily: "Montserrat",
						fontWeight: "600",
						fontSize: ".7em",
					}}>
					Click the flag <FlagIcon style={{ fontSize: "1.2em" }} /> icon to
					report unsatisfactory responses.
				</Typography>
			</Grid>
			<Grid container alignItems="center" justify="center">
				<Typography
					variant="body1"
					className="note-text"
					style={{
						fontFamily: "Montserrat",
						fontWeight: "600",
						fontSize: ".7em",
					}}>
					Click the bookmark <BookmarkIcon style={{ fontSize: "1.2em" }} /> icon
					to add message to Knowledge Bank.
				</Typography>
			</Grid>
		</Grid>
	);

	render() {
		if (this.props.knowledge.length === 0) {
			return (
				<div
					style={{
						textAlign: "center",
						marginTop: "3em",
					}}>
					Bookmark a message and it will show up here!
					{this.iconInst}
				</div>
			);
		}
		return (
			<>
				<div className="knowledge-bank">
					{this.props.knowledge.map((t, index) => (
						<div key={index} className="knowledge-container">
							<div style={{ float: "left" }}>{t.message}</div>
							<div
								className="delete"
								style={{ float: "right" }}
								onClick={() => this.unbookmark(t.index)}>
								{t.message.split(" ")[0] === "Hint:" ? null : <DeleteIcon />}
							</div>
							<div>
								{t.image ? (
									<img
										src={t.image}
										alt={t.message}
										className="knowledge-img"
									/>
								) : null}
							</div>
						</div>
					))}
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { knowledge: state.knowledge };
};
export default connect(mapStateToProps, {
	modifyBookmarkedState,
	removeFromKnowledge,
})(KnowledgeBank);
