import { connect } from "react-redux";

import React from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";

import {
	addToKnowledge,
	setHistory,
	modifyBookmarkedState,
	removeFromKnowledge,
	setIntroFlag,
	setFlagged,
	addToHistory,
} from "../actions";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookmarkIcon from "@material-ui/icons/Bookmark";

import FlagIcon from "@material-ui/icons/Flag";
import "../styles/chat.css";
import {
	Tooltip,
	Snackbar,
	Button,
	DialogActions,
	Typography,
} from "@material-ui/core";
import caseBackEnd from "../apis/caseBackEnd";

class ChatHistory extends React.Component {
	// DO NOT delete below state.
	// Used for local dialog box display

	state = {
		hintDialogOpen: false,
		snackbarOpen: false,
		currentHint: "",
	};

	// DO NOT delete above state.

	// AutoScroll functionality on new message:
	scrollToBottom = () => {
		this.messagesEnd.scrollIntoView({
			behavior: "smooth",
			block: "nearest",
			inline: "start",
		});
	};

	componentDidMount() {
		this.scrollToBottom();
	}

	componentDidUpdate() {
		this.scrollToBottom();
	}

	getMessages() {
		let messages = [];
		const historyLength = this.props.history.length;
		for (let i = historyLength - 1; i >= 0; i--) {
			messages.push();
		}
		return messages;
	}

	// Open hint dialog
	handleHintClicked(hint) {
		console.log("clicked");
		this.setState({
			hintDialogOpen: true,
			currentHint: hint,
		});
	}

	// Close hint dialog
	handleHintClosed = () => {
		this.setState({ hintDialogOpen: false });
		this.handleHintUsed();
		this.props.hintUsed();
	};

	handleHintUsed = async () => {
		const { history } = this.props;
		const hint = history.filter((item) => item.hintFlag);
		await this.props.addToHistory({
			message: hint[0].hint,
			sender: "them",
			hintFlag: false,
			bookmarked: false,
			motivation: "",
			flagged: false,

			doNotFlag: true,
		});
		// this.props.addToKnowledge(`Hint: ${hint[0].hint}`);
		this.handleHintNotUsed();
	};

	handleHintNotUsed() {
		const { history } = this.props;
		const tempHistory = history.filter((item) => !item.hintFlag);
		this.props.setHistory(tempHistory);
	}

	handleSnackbarClosed() {
		this.setState({ snackbarOpen: false });
	}

	handleFlagClicked(index, flagged) {
		if (flagged) return;

		caseBackEnd
			.post("/flag", {
				question: this.props.history[index - 1].message,
				answer: this.props.history[index].message,
				case: this.props.case,
				accountID: this.props.accountID,
			})
			.then((res) => {
				console.log(res);
				this.setState({ snackbarOpen: true });
				this.props.setFlagged(index);
			})
			.catch((e) => console.log(e));
	}

	bookmark(knowledge, index, bookmarked, image) {
		if (bookmarked) {
			this.props.removeFromKnowledge(index);
		} else {
			this.props.addToKnowledge(knowledge, index, image);
		}
		this.props.modifyBookmarkedState(!bookmarked, index);
	}

	renderBookmark(bookmarked, sender, m, index) {
		if (sender === "me") return;
		else if (bookmarked)
			return (
				<Tooltip
					arrow
					title="Click here to Bookmark this message. It will appear in the Knowledge Bank on the left.">
					<BookmarkIcon
						id="bookmark-button-on"
						onClick={() =>
							this.bookmark(m.message, index, m.bookmarked, m.image)
						}
					/>
				</Tooltip>
			);
		else
			return (
				<Tooltip
					arrow
					title="Click here to Bookmark this message. It will appear in the Knowledge Bank on the left.">
					<BookmarkBorderIcon
						id="bookmark-button-off"
						onClick={() =>
							this.bookmark(m.message, index, m.bookmarked, m.image)
						}
					/>
				</Tooltip>
			);
	}

	render() {
		const messages = this.props.history;

		const hintBox = (
			<React.Fragment>
				<div>{"Feeling Stuck? Click here for a hint"}</div>
			</React.Fragment>
		);

		return (
			<Grid container direction="column">
				{messages.length > 0 ? (
					<div />
				) : (
					<Grid
						item
						className="starter-text"
						align="center"
						style={{ marginTop: "50vh" }}>
						You haven't asked anything yet.
					</Grid>
				)}

				{messages.map((m, index) => (
					<div
						key={index}
						style={{ minWidth: "20%" }}
						className={
							m.sender === "me"
								? "message-me"
								: m.hintFlag
								? "message-hint"
								: "message"
						}
						onClick={
							m.hintFlag ? () => this.handleHintClicked(m.hint) : undefined
						}>
						{m.hintFlag ? (
							hintBox
						) : (
							<span className="message-text">
								{m.message === "failure"
									? "We're having some troubles. Please try asking again"
									: m.motivation
									? `${m.motivation} ${m.message}`
									: `${m.message}`}
							</span>
						)}
						<span>
							<span className="bookmark-container">
								{m.standardFlag || m.hintFlag || m.message === "failure"
									? ""
									: this.renderBookmark(m.bookmarked, m.sender, m, index)}

								{m.sender !== "me" ? (
									m.doNotFlag ||
									m.hintFlag ||
									m.message === "failure" ? null : m.flagged ? (
										<FlagIcon id="flag-button-red" />
									) : (
										<Tooltip
											arrow
											title="Click here to flag for unsatisfatictory response. We will use this to improve our model!">
											<FlagIcon
												id="flag-button"
												onClick={() => this.handleFlagClicked(index, m.flagged)}
											/>
										</Tooltip>
									)
								) : null}
							</span>
						</span>
						<div>
							{m.image ? (
								<img src={m.image} alt={m.message} className="message-img" />
							) : null}
						</div>
					</div>
				))}

				<Dialog
					onClose={() => this.handleHintClosed()}
					open={this.state.hintDialogOpen}>
					<div>
						<Typography align="center">Hint</Typography>
					</div>
					<div className="hint-dialog">{this.state.currentHint}</div>
					<DialogActions>
						<Button onClick={() => this.handleHintClosed()}>Okay</Button>
					</DialogActions>
				</Dialog>

				<Snackbar
					open={this.state.snackbarOpen}
					autoHideDuration={6000}
					onClose={() => this.handleSnackbarClosed()}
					message="Thanks for the feedback! We're trying to improve! :)"
				/>
				<div
					style={{ float: "left", clear: "both", marginTop: "2em" }}
					ref={(el) => {
						this.messagesEnd = el;
					}}
				/>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		history: state.history,
		username: state.accountID,
		case: state.case,
		accountID: state.user.uid,
	};
};

export default connect(mapStateToProps, {
	addToKnowledge,
	removeFromKnowledge,
	setHistory,
	modifyBookmarkedState,
	setFlagged,
	addToHistory,
})(ChatHistory);
