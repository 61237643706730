import React from "react";
import { connect } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import history from "../history";
import ProblemStatement from "./ProblemStatement";
import RightPanel from "./RightPanel";
import CaseInfoDialog from "./CaseInfoDialog";
import InfoIcon from "@material-ui/icons/Info";
import { auth } from "../firebase";
import {
	setAskValue,
	clearStore,
	setHistory,
	clearHistory,
	setTime,
} from "../actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Timer from "react-compound-timer";
import { Link } from "react-router-dom";
import "../styles/case.css";
import LeftPanel from "./LeftPanel";
import { isMobile } from "react-device-detect";
import NotForMobileScreen from "./NotForMobileScreen";
class CasePage extends React.Component {
	state = {
		time: 0,
		startTime: this.props.startTime,
		informationDialogOpen: true,
	};
	componentDidMount() {
		auth.onAuthStateChanged((user) => {
			if (!user) {
				history.push("/login");
			}
		});
		if (window.location.pathname.split("/").pop() !== this.props.case) {
			history.push("/board");
			// console.log(window.location.pathname.split("/")[-1], this.props.case);
		}
	}

	homeButtonClicked() {
		history.push("/board");
	}

	informationButtonClicked() {
		this.setState({ informationDialogOpen: true });
	}

	informationDialogClosed(start) {
		this.setState({ informationDialogOpen: false });
	}
	render() {
		if (!this.props.user.uid) {
			return <div>Redirecting...</div>;
		}
		if (isMobile) return <NotForMobileScreen />;

		return (
			<Timer
				// initialTime={new Date().getTime() - this.props.startTime}
				initialTime={this.state.startTime}
				startImmediately={false}
				onPause={() => this.informationButtonClicked()}
				onResume={() => this.informationDialogClosed()}>
				{({ getTime, resume, pause }) => (
					<Grid container direction="column" className="casePageContainer">
						<Grid container className="casePageHeader">
							<Grid container style={{ marginTop: "15px", marginLeft: "1em" }}>
								<Grid
									item
									onClick={() => this.homeButtonClicked()}
									className="home-button">
									<div style={{ float: "left" }}>
										<ArrowBackIcon />
									</div>
									<div
										style={{
											float: "left",
											marginTop: "3px",
										}}>
										Back To Home
									</div>
								</Grid>
								<Grid
									item
									style={{
										marginLeft: "auto",
										marginRight: "2em",
										fontFamily: "'Roboto Mono', monospace",
									}}>
									<>
										<Timer.Hours formatValue={(value) => `${value}:`} />
										<Timer.Minutes
											formatValue={(value) =>
												value < 10 ? `0${value}:` : `${value}:`
											}
										/>
										<Timer.Seconds
											formatValue={(value) =>
												value < 10 ? `0${value}` : `${value}`
											}
										/>
									</>
								</Grid>
								<Grid item style={{ marginRight: "2em" }}>
									<InfoIcon className="info-icon" onClick={pause} />
								</Grid>
							</Grid>
						</Grid>

						<Grid container>
							<Grid item container direction="column" sm>
								<LeftPanel title={this.props.title} />
							</Grid>
							<Grid item container direction="column" sm>
								<RightPanel getTime={getTime} />
							</Grid>
						</Grid>
						<CaseInfoDialog
							open={this.state.informationDialogOpen}
							onClose={resume}
						/>
					</Grid>
				)}
			</Timer>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		askValue: state.askValue,
		history: state.history,
		user: state.user,
		startTime: state.startTime,
		case: state.case,
	};
};

export default connect(mapStateToProps, {
	setAskValue,
	clearStore,
	setHistory,
	clearHistory,
	setTime,
})(CasePage);
