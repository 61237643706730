import React, { useState } from "react";
import {
	Card,
	Grid,
	Typography,
	Tooltip,
	Hidden,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import "../styles/case-header.css";
import TagIcon from "@material-ui/icons/LocalOffer";

export default function CaseHeader(props) {
	const [isHovering, setIsHovering] = useState(false);
	const [image, setImage] = useState("");
	const [tagsVisible, setTagsVisible] = useState(false);
	// componentDidMount() {
	//     getImage();
	// }
	const theme = useTheme();
	const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
	const handleMouseHover = () => {
		setIsHovering(!isHovering);
	};

	const renderStatus = (status) => {
		if (status === "not-attempted") {
			return (
				<Typography variant="h4" id="history" align="center">
					Not attempted
				</Typography>
			);
		} else if (status === "completed") {
			return (
				<Typography variant="h4" id="historyComplete" align="center">
					Completed
				</Typography>
			);
		} else {
			return (
				<Typography variant="h4" id="partiallyComplete" align="center">
					Partially complete
				</Typography>
			);
		}
	};

	// getImage = () => {
	//     fetch(`https://casecade.in/images/${props.header.case_id}.png`)
	//         .then((response) => {
	//             if (!response.ok) {
	//                 throw new Error("not found");
	//             }
	//             setImage('')
	//         })
	//         .catch((e) => {
	//             setImage("err");
	//         });
	// };

	// console.log(props.header);

	// const tags = toString(["tag1", "tag2"]);

	const mobileCards = (
		<Grid className="caseCardContainer">
			{/* <Tooltip title="Click to begin" enterDelay={1000}> */}
			<Card
				id="caseCardMob"
				onClick={() =>
					props.onClick(
						props.header.case_id,
						props.header.header,
						props.header.status
					)
				}>
				<Grid container>
					<Grid item>
						<div className="mobImgContainer">
							<img
								src={`https://images.casecade.in/${props.header.case_id}.png`}
								alt="test"
								className="mobImg"
							/>
						</div>
					</Grid>
					<Grid
						item
						container
						direction="column"
						alignItems="center"
						className="mobTitleContainer">
						<Grid item>
							<Typography variant="h1" id="caseHeader" align="center">
								{props.header.header}
							</Typography>
							<Typography variant="h1" id="caseSubHeader" align="center">
								{props.header.sdesc}
							</Typography>
						</Grid>
						<Grid
							item
							container
							direction="column"
							className="mobButtonContainer">
							<Grid item>{renderStatus(props.header.status)}</Grid>
							<Grid
								item
								id="diffContainer"
								className={props.header.difficulty.toLowerCase()}
								style={{ marginTop: ".5em" }}>
								<Typography
									align="center"
									id="diff"
									className={props.header.difficulty.toLowerCase()}>
									{props.header.difficulty}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Card>
			{/* </Tooltip> */}
		</Grid>
	);
	const desktopCards = (
		<Grid
			container
			justify="center"
			onMouseEnter={handleMouseHover}
			onMouseLeave={handleMouseHover}>
			<Grid className="caseCardContainer">
				{/* <Tooltip title="Click to begin" enterDelay={1000}> */}
				<Card
					id={isHovering ? "caseCardHover" : "caseCard"}
					onClick={() =>
						props.onClick(
							props.header.case_id,
							props.header.header,
							props.header.status
						)
					}>
					<Grid container>
						<Grid item>
							<div
								className={
									isHovering
										? "imageContainer imageContainerHover"
										: "imageContainer"
								}>
								<img
									src={`https://images.casecade.in/${props.header.case_id}.png`}
									alt="test"
									className={isHovering ? "cardImgHover cardImg" : "cardImg"}
								/>
							</div>
						</Grid>
						<Grid
							item
							container
							direction="column"
							justify="center"
							className={
								isHovering
									? "titleContainer titleContainerHover"
									: "titleContainer"
							}>
							<Grid item>
								<Typography variant="h1" id="caseHeader">
									{props.header.header}
								</Typography>
								<Typography variant="h1" id="caseSubHeader">
									{props.header.sdesc}
								</Typography>
							</Grid>
						</Grid>

						<Grid
							item
							container
							direction="column"
							className={
								isHovering
									? "buttonContainer buttonContainerHover"
									: "buttonContainer"
							}>
							<Grid
								item
								id="diffContainer"
								className={props.header.difficulty.toLowerCase()}>
								<Typography
									align="center"
									id="diff"
									className={props.header.difficulty.toLowerCase()}>
									{props.header.difficulty}
								</Typography>
							</Grid>
							<Grid item>{renderStatus(props.header.status)}</Grid>
						</Grid>
					</Grid>
				</Card>
				{/* </Tooltip> */}
			</Grid>
			<Grid item>
				<Tooltip title={props.header.tag.join(", ")}>
					{/* <Tooltip title="Profitability"> */}
					<TagIcon
						style={{ marginTop: "3em", marginLeft: "2em" }}
						id={isHovering ? "tagIconHover" : "tagIcon"}
						// onClick={setTagsVisible(true)}
					/>

					{/* {tagContent(props.header.tag)} */}
					{/* {tagContent(["tag1", "tag2"])} */}
				</Tooltip>
			</Grid>
		</Grid>
	);

	return matchesSM ? mobileCards : desktopCards;
}
