export const selectCase = (caseId) => {
	return {
		type: "SELECT_CASE",
		payload: caseId,
	};
};
export const addCaseHeader = (header) => {
	return {
		type: "ADD_CASE_HEADER",
		payload: header,
	};
};

export const clearHeaders = () => {
	return {
		type: "CLEAR_HEADERS",
	};
};

export const setAskValue = (value) => {
	return {
		type: "SET_ASK_VALUE",
		payload: value,
	};
};

export const addToHistory = (message) => {
	return {
		type: "ADD_TO_HISTORY",
		payload: message,
	};
};

export const setHistory = (history) => {
	return {
		type: "SET_HISTORY",
		payload: history,
	};
};

export const clearHistory = () => {
	return {
		type: "CLEAR_HISTORY",
	};
};

export const popFromHistory = () => {
	return {
		type: "POP_LAST",
	};
};

export const modifyBookmarkedState = (newBookmarked, index) => {
	return {
		type: "MODIFY_HISTORY",
		payload: {
			newBookmarked: newBookmarked,
			index: index,
		},
	};
};

export const setFlagged = (index) => {
	return {
		type: "SET_FLAGGED",
		payload: {
			index: index,
		},
	};
};

export const setUsername = (username) => {
	return {
		type: "SET_USERNAME",
		payload: username,
	};
};

export const setUser = (name, email, uid) => {
	return {
		type: "SET_USER",
		payload: {
			name,
			email,
			uid,
		},
	};
};

export const resetUser = () => {
	return {
		type: "RESET_USER",
	};
};

export const setProblemStatement = (problem) => {
	return {
		type: "SET_PROBLEM",
		payload: problem,
	};
};

export const setQuestions = (questions) => {
	return {
		type: "SET_QUESTIONS",
		payload: questions,
	};
};

export const clearStore = () => {
	return {
		type: "CLEAR_STORE",
	};
};

export const setTime = (time) => {
	return {
		type: "UPDATE_TIME",
		payload: time,
	};
};

export const setAnswers = (answer, index) => {
	return {
		type: "SET_ANSWER",
		payload: {
			answer: answer,
			index: index,
		},
	};
};

export const clearAnswers = () => {
	return {
		type: "CLEAR_ANSWERS",
	};
};

export const addToKnowledge = (message, index, image) => {
	return {
		type: "ADD_TO_KNOWLEDGE",
		payload: {
			message: message,
			index: index,
			image: image,
		},
	};
};

export const removeFromKnowledge = (index) => {
	return {
		type: "REMOVE_FROM_KNOWLEDGE",
		payload: index,
	};
};

export const setAnalysisTree = (tree) => {
	return {
		type: "SET_TREE",
		payload: tree,
	};
};

export const setCaseTitle = (title) => {
	return {
		type: "SET_CASE_TITLE",
		payload: title,
	};
};

export const setMetrics = (metrics) => {
	return {
		type: "SET_METRICS",
		payload: metrics,
	};
};

export const setAnswerAnalysis = (analysis) => {
	return {
		type: "SET_ANSWER_ANALYSIS",
		payload: analysis,
	};
};

export const setIntroFlag = () => {
	return {
		type: "SET_INTRO_FLAG",
	};
};
