import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import GradeIcon from "@material-ui/icons/Grade";
import InfoIcon from "@material-ui/icons/Info";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {
	Button,
	Grid,
	Typography,
	IconButton,
	Snackbar,
	requirePropFactory,
	Hidden,
} from "@material-ui/core";
import logo from "../assets/logoWhite.png";
import TreeView from "./TreeView";
import Blog from "./Blog";
import Dashboard from "./Dashboard";
import UserMenu from "./userMenu";
import CloseIcon from "@material-ui/icons/Close";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";

import "../styles/post-case.css";
import history from "../history";
import { auth } from "../firebase";
import Footer from "./Footer";

import { connect } from "react-redux";
import { resetUser, clearStore } from "../actions";
import { isMobile } from "react-device-detect";
import NotForMobileScreen from "./NotForMobileScreen";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: "black",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		overflow: "auto",
		fontFamily: "Montserrat",
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	menuIcon: {
		fontSize: 50,
		marginLeft: 10,
		marginTop: 5,

		"&:hover": {
			cursor: "pointer",
		},
	},
	menuIconClose: {
		fontSize: 50,
		marginLeft: "auto",
		marginTop: 5,

		"&:hover": {
			cursor: "pointer",
		},
	},
}));

function ClippedDrawer(props) {
	useEffect(() => {
		props.analysisVisited();
	}, []);
	const classes = useStyles();

	const [activeIndex, setActiveIndex] = useState(0);
	const [open, setOpen] = React.useState(true);
	const [openDrawer, setOpenDrawer] = React.useState(true);
	const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(0);

	const signOutOfApp = async () => {
		auth.signOut();
		props.resetUser();
		props.clearStore();
		history.push("/");
	};

	const renderContent = () => {
		if (activeIndex === 0) {
			return (
				<div>
					<Dashboard
						metrics={props.metrics}
						answerAnalysis={props.answerAnalysis}
						setActiveIndex={setActiveIndex}
					/>
				</div>
			);
		}
		if (activeIndex === 1) {
			return (
				<div>
					<Blog />
				</div>
			);
		}
		if (activeIndex === 2) {
			return (
				<div style={{ height: "90vh" }}>
					<TreeView />
				</div>
			);
		}
	};

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	// if (isMobile) return <NotForMobileScreen />;
	return (
		<>
			<div className={classes.root}>
				<CssBaseline />
				<AppBar position="fixed" className={classes.appBar}>
					<Grid item container className="boardHeader">
						{!openDrawer ? (
							<MenuRoundedIcon
								className={classes.menuIcon}
								onClick={() => setOpenDrawer(1)}
							/>
						) : (
							<CloseRoundedIcon
								className={classes.menuIcon}
								onClick={() => setOpenDrawer(0)}
							/>
						)}
						<Hidden smDown>
							<Grid item>
								<Button component={Link} to="/" disableRipple>
									<img src={logo} alt="Company logo" className="boardBrand" />
								</Button>
							</Grid>
						</Hidden>
						<Grid
							item
							style={{
								marginLeft: "auto",
								marginRight: "2em",
								marginTop: "1em",
							}}>
							<Typography variant="h3" id="logged-in" align="center">
								You're logged in as
							</Typography>
							<UserMenu name={props.user.name} onClickSignOut={signOutOfApp} />
						</Grid>
					</Grid>
				</AppBar>
				<Drawer
					className={classes.drawer}
					variant="temporary"
					anchor="left"
					open={openDrawer}
					onClose={() => setOpenDrawer(false)}
					classes={{
						paper: classes.drawerPaper,
					}}>
					<CloseRoundedIcon
						className={classes.menuIconClose}
						onClick={() => setOpenDrawer(0)}
					/>
					<Toolbar />
					<div className={classes.drawerContainer}>
						<Typography
							align="center"
							style={{
								fontFamily: "Montserrat",
								fontSize: ".8em",
							}}>
							You just consulted for
						</Typography>
						<Typography
							align="center"
							style={{
								fontFamily: "Montserrat",
								marginBottom: "2em",
								fontWeight: "500",
							}}>
							{props.title}
						</Typography>
						<List>
							<ListItem
								button
								selected={activeIndex === 0}
								onClick={() => setActiveIndex(0)}>
								<ListItemIcon>
									<DashboardIcon />
								</ListItemIcon>
								<ListItemText primary="Dashboard" />
							</ListItem>
							<ListItem
								button
								selected={activeIndex === 1}
								onClick={() => setActiveIndex(1)}>
								<ListItemIcon>
									<AssignmentIcon />
								</ListItemIcon>
								<ListItemText primary="Solution" />
							</ListItem>
							<ListItem
								button
								selected={activeIndex === 2}
								onClick={() => setActiveIndex(2)}>
								<ListItemIcon>
									<AccountTreeIcon />
								</ListItemIcon>
								<ListItemText primary="Tree View" />
							</ListItem>
						</List>
						<Divider />
						{/* <List style={{ fontSize: ".75em" }}>
                            <ListItem button>
                                <ListItemIcon>
                                    <GradeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Liked our App? Support Us!" />
                            </ListItem>
                            <ListItem button>
                                <ListItemIcon>
                                    <MarkunreadMailboxIcon />
                                </ListItemIcon>
                                <ListItemText primary="Subscribe to Newsletter" />
                            </ListItem>
                            <ListItem button>
                                <ListItemIcon>
                                    <InfoIcon />
                                </ListItemIcon>
                                <ListItemText primary="About" />
                            </ListItem>
                        </List> */}

						<List style={{ fontSize: ".75em" }}>
							{/* <ListItem
                                button
                                onClick={() =>
                                    history.push(`/case/${props.case}`)
                                }
                            >
                                <ListItemIcon>
                                    <ArrowBackIcon />
                                </ListItemIcon>
                                <ListItemText primary="Go back" />
                            </ListItem> */}
							<ListItem button onClick={() => history.push("/board")}>
								<ListItemIcon>
									<FiberNewIcon />
								</ListItemIcon>
								<ListItemText primary="Solve new case" />
							</ListItem>
						</List>
						<Divider />
						<Button
							disableRipple
							variant="outlined"
							label={"Blog"}
							href="https://pages.razorpay.com/pl_FHNaQi8aTR5fvX/view"
							rel="noreferrer noopener"
							target="__blank"
							id="tabsWhiteImp"
							style={{
								width: "10em",
								fontWeight: 600,
								borderRadius: "20px",
								marginLeft: "3em",
								marginTop: "2em",
								marginBottom: "2em",
							}}>
							Support Us
						</Button>
						<Divider />
						<List>
							<ListItem
								button
								component="a"
								href="https://docs.google.com/forms/d/e/1FAIpQLSff8vYYkC1r_z2TD-GPiw0RFao-myf3j-9u9kZEZvADdbujlQ/viewform?usp=sf_link"
								target="__blank">
								<ListItemIcon>
									<GradeIcon />
								</ListItemIcon>
								<ListItemText primary="Give us Feedback!" />
							</ListItem>
						</List>
					</div>
				</Drawer>
				<main className={classes.content}>
					<Toolbar />
					{renderContent()}
				</main>
			</div>
			<div>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					open={open}
					onClose={handleClose}
					message="Take a moment to help us out please!"
					action={
						<React.Fragment>
							<Button
								color="primary"
								size="small"
								onClick={handleClose}
								component="a"
								href="https://docs.google.com/forms/d/e/1FAIpQLSff8vYYkC1r_z2TD-GPiw0RFao-myf3j-9u9kZEZvADdbujlQ/viewform?usp=sf_link"
								target="__blank"
								rel="noreferrer noopener"
								style={{ marginRight: "1em", color: "#ff6f7c" }}>
								Feedback
							</Button>
							<Button
								color="primary"
								size="small"
								onClick={handleClose}
								component="a"
								href="https://pages.razorpay.com/pl_FHNaQi8aTR5fvX/view"
								rel="noreferrer noopener"
								target="__blank"
								style={{ color: "#ff6f7c" }}>
								Support us
							</Button>
							<IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={handleClose}>
								<CloseIcon fontSize="small" />
							</IconButton>
						</React.Fragment>
					}
				/>
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
	};
};
export default connect(mapStateToProps, { resetUser, clearStore })(
	ClippedDrawer
);
