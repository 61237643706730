export const Founders = [
	{
		name: "Parambir Singh",
		picture: "https://imgur.com/HDb6UDN.jpg",
		linkedin: "https://www.linkedin.com/in/parambir-singh-/",
	},
	{
		name: "Siddak Bakshi",
		picture: "https://imgur.com/aOz18ze.jpg",
		linkedin: "https://www.linkedin.com/in/siddak-bakshi-1115a814a/",
	},
	{
		name: "Devashish Rane",
		picture: "https://imgur.com/YOfI4pI.jpg",
		linkedin: "https://www.linkedin.com/in/devashish-rane-5585b614a/",
	},
	{
		name: "Dhruv Kaluskar",
		picture: "https://imgur.com/7XLFdB4.jpg",
		linkedin: "https://www.linkedin.com/in/dhruv-kaluskar-a846a3147/",
	},
	{
		name: "Neel Daftary",
		picture: "https://imgur.com/LF3YZE0.jpg",
		linkedin: "https://www.linkedin.com/in/neeldaftary/",
	},
];

export const FoundingTeam = [
	{
		name: "Manika Kanjani",
		picture: "https://imgur.com/boYJZxk.jpg",
		linkedin: "https://www.linkedin.com/in/manika-kanjani-637431b9/",
	},
	{
		name: "Jaskaran Bhatia",
		picture: "https://imgur.com/wtgngx1.jpg",
		linkedin: "https://www.linkedin.com/in/jaskaransbhatia/",
	},
	{
		name: "Richa Naik",
		picture: "https://imgur.com/rHavNBM.jpg",
		linkedin: "https://www.linkedin.com/in/richa-naik-54b497185/",
	},
	{
		name: "Viren Jindal",
		picture: "https://imgur.com/2MZTDm6.jpg",
		linkedin: "https://www.linkedin.com/in/viren-jindal-850628159/",
	},
	{
		name: "Siddhant Parekh",
		picture: "https://imgur.com/aFypj0f.jpg",
		linkedin: "https://www.linkedin.com/in/siddhant-parekh-03a392171/",
	},
];
