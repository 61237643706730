import React, { useState } from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import "../styles/landing.css";
import {
    Grid,
    CssBaseline,
    useScrollTrigger,
    Typography,
    Card,
} from "@material-ui/core";

import { Founders, FoundingTeam } from "../assets/team";

import TeamCard from "./TeamCard";
import Footer from "./Footer";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
    rootCard: {
        maxWidth: "70em",
        width: "80vw",
        paddingLeft: "10em",
        paddingRight: "10em",
        paddingTop: "5em",
        marginBottom: "5em",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "3em",
            paddingRight: "3em",
            textAlign: "center",
        },
    },

    title: {
        opacity: "1",
        transitionDuration: ".2s",
        fontSize: "2em",
        textTransform: "uppercase",
        fontWeight: 600,
        marginBottom: "1em",
    },
    quote: {
        fontStyle: "italic",
    },
    teamCardContainer: {
        width: "15em",
        [theme.breakpoints.down("xs")]: { width: "10em" },
    },

    teamCardContainerAlt: {
        width: "15em",
        [theme.breakpoints.down("xs")]: { width: "10em" },
    },
}));

export default (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Grid container direction="column">
            <CssBaseline />
            <Header user={props.user} />

            <Grid
                container
                direction="column"
                className="boardBackground"
                alignItems="center"
            >
                <Grid
                    item
                    align="center"
                    style={{
                        marginLeft: "10em",
                        marginRight: "10em",
                        marginTop: "5em",
                        marginBottom: "2em",
                    }}
                ></Grid>

                <Card className={classes.rootCard}>
                    <Grid item>
                        <Typography
                            variant="h1"
                            className={classes.title}
                            style={{ fontFamily: "Montserrat" }}
                            align="center"
                        >
                            About Us
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        style={{ marginBottom: "5em" }}
                    >
                        <Grid item style={{ marginBottom: "2em" }}>
                            <Typography
                                className={classes.quote}
                                style={{ fontFamily: "Montserrat" }}
                            >
                                “The zeal to work towards creating something of
                                your own is what ignites passion!”
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                paragraph
                                style={{ fontFamily: "Montserrat" }}
                            >
                                Exploring our entrepreneurial spirit during this
                                challenging lock-down that has gripped the
                                world, we bumped into an idea that could help us
                                create a positive impact.
                            </Typography>
                            <Typography
                                paragraph
                                style={{ fontFamily: "Montserrat" }}
                            >
                                That is how the journey of Casecade has begun.
                                Our team aims to diffuse the aura of confusion
                                around the term ‘Consulting’. We want to make
                                the skills learnt through this field more
                                understandable for everyone so that they can
                                implement it in their own daily lives.
                            </Typography>
                            <Typography
                                paragraph
                                style={{ fontFamily: "Montserrat" }}
                            >
                                For a team that does not draw its boundaries
                                within set patterns of working and thrives in
                                chaos, we hope to bridge the gaps in the
                                Consulting world and make a mark of our own!
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="column" alignItems="center">
                        <Grid item style={{ marginBottom: "2em" }}>
                            <Typography
                                variant="h4"
                                justify="center"
                                style={{
                                    color: "#000",
                                    fontSize: "2em",
                                    fontFamily: "Montserrat",
                                    textTransform: "uppercase",
                                }}
                            >
                                Our Team
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginBottom: "2em" }}>
                            <Typography
                                variant="h4"
                                justify="center"
                                style={{
                                    color: "#000",
                                    fontSize: "1.5em",
                                    fontFamily: "Montserrat",
                                }}
                            >
                                Founders
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            style={{ marginBottom: "2em" }}
                            alignItems="center"
                            justify="center"
                        >
                            {Founders.map((person, index) => (
                                <Grid
                                    item
                                    key={index}
                                    className={classes.teamCardContainer}
                                >
                                    <TeamCard person={person} />
                                </Grid>
                            ))}
                        </Grid>

                        <Grid item style={{ marginBottom: "2em" }}>
                            <Typography
                                variant="h4"
                                justify="center"
                                style={{
                                    color: "#000",
                                    fontSize: "1.5em",
                                    fontFamily: "Montserrat",
                                }}
                            >
                                Founding Team
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            style={{ marginBottom: "2em" }}
                            alignItems="center"
                            justify="center"
                        >
                            {FoundingTeam.map((person, index) => (
                                <Grid
                                    item
                                    key={index}
                                    className={classes.teamCardContainerAlt}
                                >
                                    <TeamCard person={person} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Footer />
        </Grid>
    );
};
