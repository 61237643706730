import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import ChatHistory from "./ChatHistory";
import ChatTextBox from "./ChatTextBox";

import { connect } from "react-redux";
import AnswerBox from "./AnswerBox";

import "../styles/expansion-panel.css";

class RightPanel extends React.Component {
	// DO NOT delete below state
	// Used for managing expansion panels
	state = {
		expanded: true,
		hintIndex: 0,
	};
	// DO NOT delte above state

	// Handle expansion panel change
	handleChange = () => () => {
		this.setState({ expanded: !this.state.expanded });
	};

	hintUsed = () => {
		this.setState({ hintIndex: this.state.hintIndex + 1 });
	};

	render() {
		return (
			<div style={{ margin: "2em" }}>
				<ExpansionPanel
					expanded={this.state.expanded}
					onChange={this.handleChange()}>
					<ExpansionPanelSummary
						aria-controls="panel1d-content"
						id="panel1d-header">
						<Typography>Chat Box</Typography>
						<div style={{ marginLeft: "auto" }}>
							{this.state.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</div>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid container>
							<Grid item className="history-container">
								<ChatHistory
									addToKnowledge={this.props.addToKnowledge}
									hintUsed={this.hintUsed}
								/>
							</Grid>
							<Grid item container>
								<ChatTextBox
									hintIndex={this.state.hintIndex}
									getTime={this.props.getTime}
								/>
							</Grid>
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
				<ExpansionPanel
					expanded={!this.state.expanded}
					onChange={this.handleChange()}>
					<ExpansionPanelSummary
						aria-controls="panel2d-content"
						id="panel2d-header">
						<Typography>
							{!this.state.expanded ? "Answer" : "Ready to Answer?"}
						</Typography>
						<div style={{ marginLeft: "auto" }}>
							{!this.state.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</div>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid container direction="column">
							<AnswerBox getTime={this.props.getTime} />
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return { questions: state.questions };
};

export default connect(mapStateToProps)(RightPanel);
