import React from "react";
import { connect } from "react-redux";
import { Router, Route } from "react-router-dom";
import history from '../history'
import { selectCase, setUsername, clearStore, setCaseTitle } from "../actions";
import CasePage from "./CasePage";
import Board from "./Board";
import Blog from "./Blog";
import Login from "./Login";
import PostCase from "./PostCase";
import LandingPage from "./LandingPage";
import About from "./About";
import ReactGA from "react-ga";

ReactGA.initialize("UA-176279070-1");

// history.listen((location) => {
// 	ReactGA.set({ page: location.pathname });
// 	ReactGA.pageview(location.pathname);
// });
class App extends React.Component {
	handleClick = async (id, header) => {
		await this.props.clearStore();
		await this.props.selectCase(id);
		this.props.setCaseTitle(header);
		history.push(`/case/${id}`);
	};


	analysisVisited = () =>{
		ReactGA.event({category: "user", action: "Visited Post Case"});
		console.log("Analysis")
	}
	componentDidMount = () =>
		ReactGA.pageview(window.location.pathname + window.location.search);
	componentDidUpdate = () =>
		ReactGA.pageview(window.location.pathname + window.location.search);

	render() {
		return (
			<Router history={history}>
				<Route path="/login" exact render={(props) => <Login {...props} />} />

				<Route
					path="/case/:id"
					exact
					render={(props) => <CasePage {...props} />}
				/>
				<Route
					path="/board"
					exact
					render={(props) => (
						<Board
							{...props}
							setName
							clicked={this.props.case}
							onClick={(i, header) => this.handleClick(i, header)}
						/>
					)}
				/>
				<Route path="/blog" component={()=>{
					window.location="https://blog.casecade.in"+window.location.pathname
					return null;
				}}  />
				<Route path="/2020/06/24/a-primer-to-case-interviews-" component={()=>{
					window.location="https://blog.casecade.in/blog/a-primer-to-case-interviews/"
					return null;
				}}  />
				<Route path="/2020/07/02/careers-in-consulting" component={()=>{
					window.location="https://blog.casecade.in/blog/careers-in-consulting/"
					return null;
				}}  />
				<Route
					path="/analysis"
					exact
					render={(props) => (
						<PostCase
							{...props}
							metrics={this.props.metrics}
							user={this.props.user}
							title={this.props.title}
							answerAnalysis={this.props.answerAnalysis}
							case={this.props.case}
							analysisVisited={this.analysisVisited}
						/>
					)}
				/>
				<Route
					path="/"
					exact
					render={(props) => <LandingPage {...props} user={this.props.user} />}
				/>
				<Route
					path="/about"
					exact
					render={(props) => <About {...props} user={this.props.user} />}
				/>
			</Router>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		case: state.case,
		username: state.accountID,
		user: state.user,
		metrics: state.metrics,
		title: state.caseTitle,
		answerAnalysis: state.answerAnalysis,
	};
};

export default connect(mapStateToProps, {
	selectCase,
	setUsername,
	clearStore,
	setCaseTitle,
})(App);
