import React from "react";
import { Grid, Card, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CircularProgressWithLabel from "./CircularProgressWithLabel";

import "../styles/dashboard.css";
import ChatHistoryDashboard from "./ChatHistoryDashboard";

const useStyles = makeStyles(() => ({
	card: {
		minHeight: "20em",
		margin: "1%",
		boxSizing: "border-box",
		minWidth: "15em",
		width: "23%",
	},

	cardHeader: {
		color: "#000",
		fontSize: "1em",
		textTransform: "uppercase",
		fontFamily: "Montserrat",
		padding: "10px",
		textAlign: "center",
	},

	cardContent: {
		color: "#000",
		fontSize: "1em",
		fontFamily: "Montserrat",
		padding: "10px",
	},
	cardBody: {
		color: "#000",
		fontSize: ".8em",
		textTransform: "uppercase",
		fontFamily: "Montserrat",
		padding: "10px",
		paddingBottom: "0",
		textAlign: "center",
	},
	time: {
		fontFamily: "roboto mono",
		fontWeight: 900,
		fontSize: "2em",
		textAlign: "center",
		color: "#333",
	},

	timeRegular: {
		fontFamily: "roboto mono",
		fontWeight: 900,
		fontSize: "1em",
		textAlign: "center",
		color: "#333",
	},
	unit: {
		fontFamily: "roboto mono",
		fontSize: ".75em",
		textAlign: "center",
		marginTop: "-1em",
	},

	trueAnswerCard: {
		margin: "1%",
		width: "48%",
		padding: "2em",
		paddingTop: "0",
		maxHeight: "30em",
		overflowY: "scroll",
		minWidth: "30em",
	},
	historyCard: {
		margin: "1%",
		width: "48%",
		padding: "2em",
		paddingTop: "0",
		maxHeight: "30em",
		overflowY: "scroll",
		minWidth: "30em",
	},
}));

const renderTime = (time) => {
	const seconds = Math.floor(time / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);

	return (
		<>
			<span>{hours ? `${hours}:` : ""}</span>
			<span>
				{minutes % 60 < 10 ? `0${minutes % 60}:` : `${minutes % 60}:`}
			</span>
			<span>{seconds % 60 < 10 ? `0${seconds % 60}` : `${seconds % 60}`}</span>
		</>
	);
};

const renderQuestions = (questions) => {
	return <span>{questions}</span>;
};

const renderPercentage = (percentage) => {
	return <CircularProgressWithLabel value={percentage} />;
};

export default function Dashboard(props) {
	const classes = useStyles();
	console.log(props.metrics);
	const { metrics } = props;

	const renderAnswerCards = (answerAnalysis) => {
		console.log(answerAnalysis);
		return answerAnalysis.qType.map((type, index) => {
			if (type === null || type === "qualitative") {
				return (
					<Card className={classes.trueAnswerCard} key={index}>
						<Typography
							variant="h5"
							className={classes.cardHeader}
							style={{ fontWeight: 600 }}>
							Question {index + 1}
						</Typography>
						<Typography variant="h5" className={classes.cardContent}>
							{answerAnalysis.questions[index].question}
						</Typography>
						<Typography
							variant="h5"
							className={classes.cardBody}
							style={{ textAlign: "left", marginTop: ".5em" }}>
							Your answer was:
						</Typography>

						<Typography variant="h5" className={classes.cardContent}>
							{answerAnalysis.user[index]}
						</Typography>

						<Typography
							variant="h5"
							className={classes.cardBody}
							style={{ textAlign: "left", marginTop: ".5em" }}>
							An ideal answer is:
						</Typography>
						{console.log(answerAnalysis.ideal[index].split(/\n/))}
						{answerAnalysis.ideal[index].split(/\n/).map((i) => {
							return (
								<Typography variant="h5" className={classes.cardContent}>
									{i}
								</Typography>
							);
						})}
					</Card>
				);
			} else if (type === false) {
				return (
					<Card className={classes.trueAnswerCard} key={index}>
						<Typography
							variant="h5"
							className={classes.cardHeader}
							style={{ fontWeight: 600 }}>
							Question {index + 1}
						</Typography>
						<Typography variant="h5" className={classes.cardContent}>
							{answerAnalysis.questions[index].question}
						</Typography>

						<Typography
							variant="h5"
							className={classes.cardContent}
							style={{ color: "#ee3311" }}>
							You answered {answerAnalysis.user[index]}, which is wrong.
						</Typography>

						<Typography variant="h5" className={classes.cardContent}>
							The correct answer is {answerAnalysis.ideal[index]}.
						</Typography>
					</Card>
				);
			} else
				return (
					<Card className={classes.trueAnswerCard} key={index}>
						<Typography
							variant="h5"
							className={classes.cardHeader}
							style={{ fontWeight: 600 }}>
							Question {index + 1}
						</Typography>
						<Typography variant="h5" className={classes.cardContent}>
							{answerAnalysis.questions[index].question}
						</Typography>

						<Typography
							variant="h5"
							className={classes.cardContent}
							style={{ color: "#5a2" }}>
							You answered {answerAnalysis.user[index]}, which is correct!
						</Typography>
					</Card>
				);
		});
	};

	return (
		<Grid container direction="column" alignItems="center">
			<Grid container>
				<Card className={classes.card}>
					<Grid container direction="column">
						<Grid item style={{ marginTop: "5em" }}>
							<Typography variant="h5" className={classes.cardHeader}>
								You solved the case in
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.time}>
								{renderTime(metrics.time.userTime)}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="h5" className={classes.cardBody}>
								Versus a recommended
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.timeRegular}>
								{renderTime(metrics.time.avgTime)}
							</Typography>
						</Grid>
					</Grid>
				</Card>
				<Card className={classes.card} onClick={() => props.setActiveIndex(1)}>
					{" "}
					<Grid container direction="column">
						<Grid item style={{ marginTop: "5em" }}>
							<Typography variant="h5" className={classes.cardHeader}>
								You asked
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.time}>
								{renderQuestions(metrics.questions.userQ)}
							</Typography>
							<Typography className={classes.unit}>questions</Typography>
						</Grid>
						<Grid item>
							<Typography variant="h5" className={classes.cardBody}>
								Versus a recommended
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.timeRegular}>
								{renderQuestions(metrics.questions.avgQ)}
							</Typography>
						</Grid>
					</Grid>
				</Card>
				<Card className={classes.card} onClick={() => props.setActiveIndex(2)}>
					<Grid container direction="column">
						<Grid item style={{ marginTop: "5em" }}>
							<Typography variant="h5" className={classes.cardHeader}>
								You covered
							</Typography>
						</Grid>
						<Grid
							item
							container
							direction="column"
							alignItems="center"
							justify="center">
							<Grid item>{renderPercentage(metrics.nodes.userNodes)}</Grid>
							<Grid item>
								<Typography
									className={classes.unit}
									style={{ marginTop: ".5em" }}>
									of Nodes
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Card>
				<Card className={classes.card}>
					<Grid container direction="column">
						<Grid item style={{ marginTop: "2.5em" }}>
							<Typography variant="h5" className={classes.cardHeader}>
								You asked
							</Typography>
						</Grid>
						<Grid
							item
							container
							direction="column"
							alignItems="center"
							justify="center">
							<Typography className={classes.time} style={{ color: "#5a2" }}>
								{renderQuestions(metrics.relevancyRatio.relevant)}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="h5" className={classes.cardBody}>
								relevant questions and
							</Typography>
						</Grid>

						<Grid
							item
							container
							direction="column"
							alignItems="center"
							justify="center">
							<Typography
								className={classes.time}
								style={{ marginTop: ".5em", color: "#ee3311" }}>
								{renderQuestions(metrics.relevancyRatio.irrelevant)}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="h5" className={classes.cardBody}>
								irrelevant questions
							</Typography>
						</Grid>
					</Grid>
				</Card>
				<Grid container>
					{renderAnswerCards(props.answerAnalysis)}
					<Card className={classes.historyCard}>
						<Typography
							variant="h5"
							className={classes.cardHeader}
							style={{ fontWeight: 600 }}>
							Chat Transcript
						</Typography>
						<ChatHistoryDashboard />
					</Card>
				</Grid>
			</Grid>
			<Grid container></Grid>
			<Grid container></Grid>
		</Grid>
	);
}
