import React from "react";
import { Avatar, Grid, Typography, Button } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
export default function TeamCard(props) {
    return (
        <>
            <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
                style={{ marginBottom: "2em" }}
            >
                <Grid item>
                    <Avatar
                        src={props.person.picture}
                        component="span"
                        style={{ width: "5em", height: "5em" }}
                    />
                </Grid>
                <Grid item>
                    <Typography
                        align="center"
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: 600,
                            fontSize: ".8em",
                            marginTop: "1em",
                        }}
                    >
                        {props.person.name}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        href={props.person.linkedin}
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        <LinkedInIcon />
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}
