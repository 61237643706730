import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
	useMediaQuery,
	Hidden,
	IconButton,
	SwipeableDrawer,
} from "@material-ui/core";

import "../styles/landing.css";
import { Link } from "react-router-dom";
import {
	Grid,
	Toolbar,
	AppBar,
	Button,
	useScrollTrigger,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import logoWhite from "../assets/logoWhite2.png";
import logoBlack from "../assets/logoBlack.png";

function ElevationScroll(props) {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 50,

		target: window ? window() : undefined,
	});

	return React.cloneElement(children, {
		elevation: trigger ? 4 : 0,
	});
}

const useStyles = makeStyles((theme) => ({
	featureText: {
		fontFamily: "Montserrat",
		fontWeight: 600,
	},

	featureTitle: {
		fontFamily: "Montserrat",
		textTransform: "uppercase",
	},
	brandOff: {
		width: "15em",
		opacity: 0,
		[theme.breakpoints.down("sm")]: {
			width: "10em",
		},
	},
	brandOn: {
		width: "15em",
		[theme.breakpoints.down("sm")]: {
			width: "10em",
		},
	},
	root: {
		display: "flex",
	},
	appBar: {
		backgroundColor: "black",
		transition: ".2s",
	},

	appBarScroll: {
		backgroundColor: "transparent",
		transition: ".2s",
	},
	tabs: {
		width: "10em",
		[theme.breakpoints.down("sm")]: {
			width: "6em",
		},
	},

	menu: {
		borderRadius: "0px",
	},

	drawerIconContainer: {
		marginLeft: "auto",
		"&:hover": {
			backgroundColor: "transparent",
		},
	},

	drawerIcon: {
		height: "30px",
		width: "30px",
		marginRight: -10,
	},
	drawerIconWhite: {
		height: "30px",
		width: "30px",
		marginRight: -10,
		color: "#fff",
	},

	drawer: {
		backgroundColor: theme.palette.common.blue,
		width: "10em",
	},

	drawerItem: {
		color: "black",
		opacity: 0.7,
		fontFamily: "Montserrat",
		"&:hover": {
			color: "#ff4f5a",
		},
	},

	drawerItemSelected: {
		"& .MuiListItemTex-root": {
			opacity: 1,
		},
	},

	support: {
		width: "12em",
		fontWeight: 600,
		borderRadius: "20px",
		[theme.breakpoints.down("sm")]: {
			fontSize: ".8em",
		},
	},
}));

export default function Header(props) {
	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
	const classes = useStyles();

	const [openDrawer, setOpenDrawer] = React.useState(false);
	const trigger = useScrollTrigger({
		threshold: 50,
		disableHysteresis: true,
	});
	const tabs = (
		<>
			<Button
				disableRipple
				label={"Home"}
				component={Link}
				to="/"
				id={trigger ? "tabsWhite" : "tabsBlack"}
				style={{ marginLeft: "auto" }}
				className={classes.tabs}>
				Home
			</Button>
			<Button
				disableRipple
				label={"About"}
				component={Link}
				to="/about"
				id={trigger ? "tabsWhite" : "tabsBlack"}
				className={classes.tabs}>
				About
			</Button>
			<Button
				disableRipple
				label={"Blog"}
				id={trigger ? "tabsWhite" : "tabsBlack"}
				href="http://blog.casecade.in/"
				className={classes.tabs}>
				Blog
			</Button>

			{props.user.uid ? (
				<Button
					disableRipple
					label={"Sign In"}
					id={trigger ? "tabsWhite" : "tabsBlack"}
					component={Link}
					className={classes.tabs}
					to="/login">
					Solve!
				</Button>
			) : (
				<Button
					disableRipple
					label={"Sign In"}
					id={trigger ? "tabsWhite" : "tabsBlack"}
					component={Link}
					className={classes.tabs}
					to="/login">
					Sign In
				</Button>
			)}
			<Button
				disableRipple
				variant="outlined"
				id="support"
				href="https://pages.razorpay.com/pl_FHNaQi8aTR5fvX/view"
				rel="noreferrer noopener"
				target="__blank"
				className={classes.support}>
				Support Us
			</Button>
		</>
	);

	const drawer = (
		<>
			<SwipeableDrawer
				anchor="right"
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
				onOpen={() => setOpenDrawer(true)}
				classes={{ paper: classes.drawer }}>
				<div style={{ marginTop: "1em" }} />

				<List disablePadding>
					<ListItem
						divider
						button
						component={Link}
						to="/"
						classes={{ selected: classes.drawerItemSelected }}
						onClick={() => {
							setOpenDrawer(false);
						}}>
						<ListItemText className={classes.drawerItem} disableTypography>
							Home
						</ListItemText>
					</ListItem>

					<ListItem
						divider
						button
						component={Link}
						to="/about"
						classes={{ selected: classes.drawerItemSelected }}
						onClick={() => {
							setOpenDrawer(false);
						}}>
						<ListItemText className={classes.drawerItem} disableTypography>
							About
						</ListItemText>
					</ListItem>

					<ListItem
						divider
						button
						component="a"
						href="https://blog.casecade.in"
						classes={{ selected: classes.drawerItemSelected }}
						onClick={() => {
							setOpenDrawer(false);
						}}>
						<ListItemText className={classes.drawerItem} disableTypography>
							Blog
						</ListItemText>
					</ListItem>

					<ListItem
						divider
						button
						component={Link}
						to="/login"
						classes={{ selected: classes.drawerItemSelected }}
						onClick={() => {
							setOpenDrawer(false);
						}}>
						<ListItemText className={classes.drawerItem} disableTypography>
							Sign in
						</ListItemText>
					</ListItem>

					<ListItem
						onClick={() => {
							setOpenDrawer(false);
						}}>
						<Button
							disableRipple
							variant="outlined"
							id="support"
							href="https://pages.razorpay.com/pl_FHNaQi8aTR5fvX/view"
							rel="noreferrer noopener"
							target="__blank"
							style={{
								width: "10em",
								fontWeight: 600,
								borderRadius: "20px",
								fontSize: ".75em",
							}}>
							Support Us
						</Button>
					</ListItem>
				</List>
			</SwipeableDrawer>
			<IconButton
				className={classes.drawerIconContainer}
				onClick={() => setOpenDrawer(!openDrawer)}
				disableRipple>
				<MenuIcon
					className={trigger ? classes.drawerIconWhite : classes.drawerIcon}
				/>
			</IconButton>
		</>
	);

	return (
		<Grid container>
			<ElevationScroll {...props}>
				<AppBar
					position="fixed"
					className={trigger ? classes.appBar : classes.appBarScroll}>
					<Toolbar>
						<Button component={Link} to="/" disableRipple>
							<img
								src={logoBlack}
								alt="Company logo"
								className={trigger ? classes.brandOff : classes.brandOn}
								style={{ marginLeft: "-1em" }}
							/>
							<img
								src={logoWhite}
								alt="Company logo"
								className={!trigger ? classes.brandOff : classes.brandOn}
								style={{ marginLeft: "-10em" }}
							/>
						</Button>
						<Hidden xsDown>{tabs}</Hidden>
						<Hidden smUp>{drawer}</Hidden>
					</Toolbar>
				</AppBar>
			</ElevationScroll>
		</Grid>
	);
}
