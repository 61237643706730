import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import SendButtonIcon from "@material-ui/icons/Send";
import {
	setAskValue,
	addToHistory,
	popFromHistory,
	setHistory,
	setIntroFlag,
	setTime,
} from "../actions";
import BackEnd from "../apis/caseBackEnd";

import "../styles/chat.css";
import { Grid } from "@material-ui/core";

class ChatTextBox extends React.Component {
	state = {
		askDisabled: false,
	};
	renderTextBox = (props) => {
		// console.log(props)
		return (
			<input
				className="chat-input"
				id="outlined-multiline-flexible"
				label="Type Your Questions Here"
				{...props.input}
			/>
		);
	};

	handleHintNotUsed() {
		const { history } = this.props;
		const tempHistory = history.filter((item) => !item.hintFlag);
		this.props.setHistory(tempHistory);
	}

	onAsk = async ({ chatBox }) => {
		if (!chatBox) return;
		await this.props.setAskValue(chatBox);
		this.setState({ askDisabled: true });
		this.handleAsk();
		this.props.setAskValue(null);
		this.props.reset();
	};

	fetchAsk = async (text) => {
		this.props.setTime(this.props.getTime());
		console.log({
			question: text,
			case: this.props.case,
			accountID: this.props.username,
			hintIndex: this.props.hintIndex,
			time: this.props.getTime(),
		});

		return await BackEnd.post("/ask", {
			question: text,
			case: this.props.case,
			accountID: this.props.user.uid,
			hintIndex: this.props.hintIndex,
			time: this.props.getTime(),
		});
	};

	handleAsk() {
		const text = this.props.askValue;
		console.log(text);
		this.handleHintNotUsed();

		if (text.length === 0) return;
		if (text.trim() === "") return;

		const { addToHistory } = this.props;

		addToHistory({
			message: text,
			sender: "me",
			hintFlag: false,
			hint: null,
			bookmarked: true,
			motivation: "",
		});
		addToHistory({
			message: "Thinking...",
			sender: "them",
			hintFlag: false,
			hint: null,
			bookmarked: true,
			standardFlag: true,
			motivation: "",
			doNotFlag: true,
		});
		this.fetchAsk(text)
			.then((response) => {
				this.setState({ askDisabled: false });
				console.log(response);
				const data = response.data;

				this.props.popFromHistory();
				addToHistory({
					message: data["response"],
					sender: "them",
					hintFlag: false,
					hint: "",
					bookmarked: false,
					image: data["image"],
					standardFlag: data["standardFlag"],
					motivation: data["motivation"],
					flagged: false,
				});
				if (data.hintFlag) {
					if (!data.hint) return;
					addToHistory({
						message: data["response"],
						sender: "them",
						hintFlag: data["hintFlag"],
						hint: data["hint"],
						bookmarked: true,
						motivation: "",
						flagged: false,
					});
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({ askDisabled: false });
			});
	}

	componentDidMount() {
		console.log(this.props.user);
		if (this.props.introFlag) return;
		console.log(this.props.history);
		if (this.props.history.length > 0) return;
		this.props.setIntroFlag();
		const startMessage1 = `Hey ${this.props.user.name},
        welcome to Casecade Consulting!`;
		const startMessage2 = `
        I hold the position of a Senior Consultant here.
        `;
		const startMessage3 = `
        Today we are consulting for ${this.props.title}. 
        `;
		const startMessage4 = `
        You can ask any number of relevant questions about the problem statement in the Chat Box, I'll try and answer to the best of my capabilities!`;
		this.props.addToHistory({
			message: startMessage1,
			sender: "them",
			hintFlag: false,
			hint: "",
			bookmarked: false,
			standardFlag: true,
			doNotFlag: true,
		});
		this.props.addToHistory({
			message: startMessage2,
			sender: "them",
			hintFlag: false,
			hint: "",
			bookmarked: false,
			standardFlag: true,
			doNotFlag: true,
		});
		this.props.addToHistory({
			message: startMessage3,
			sender: "them",
			hintFlag: false,
			hint: "",
			bookmarked: false,
			standardFlag: true,
			doNotFlag: true,
		});
		this.props.addToHistory({
			message: startMessage4,
			sender: "them",
			hintFlag: false,
			hint: "",
			bookmarked: false,
			standardFlag: true,
			doNotFlag: true,
		});
	}
	render() {
		return (
			<form
				className="chat-box-form"
				onSubmit={this.props.handleSubmit(this.onAsk)}
				autoComplete="off">
				<Grid container style={{ marginTop: "10px" }}>
					<Grid item style={{ width: "95%" }}>
						<Field name="chatBox" component={this.renderTextBox} />
					</Grid>
					<Grid item style={{ width: "5%", marginTop: "2px" }}>
						<button
							className="ask-button"
							type="submit"
							id="ask"
							disabled={this.state.askDisabled}>
							<SendButtonIcon />
						</button>
					</Grid>
				</Grid>
			</form>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		case: state.case,
		askValue: state.askValue,
		history: state.history,
		username: state.accountID,
		user: state.user,
		title: state.caseTitle,
		startTime: state.startTime,
		introFlag: state.introFlag,
	};
};

ChatTextBox = reduxForm({
	form: "chatTextBox",
})(ChatTextBox);

export default connect(mapStateToProps, {
	setHistory,
	setAskValue,
	addToHistory,
	popFromHistory,
	setIntroFlag,
	setTime,
})(ChatTextBox);
