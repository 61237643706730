export default (state = {}, action) => {
    if (action.type === "SET_USER") {
        const { name, email, uid } = action.payload;
        return { name, email, uid };
    } else if (action.type === "RESET_USER") {
        return {};
    }

    return state;
};
