import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const authDomain = "casenova-ee9fd.firebaseapp.com";

const firebaseConfig = {
    apiKey: "AIzaSyCEw49HMRh4a5EP82ClGOKl5DA6WWb7igA",
    authDomain: "casenova-ee9fd.firebaseapp.com",
    databaseURL: "https://casenova-ee9fd.firebaseio.com",
    projectId: "casenova-ee9fd",
    storageBucket: "casenova-ee9fd.appspot.com",
    messagingSenderId: "925970610855",
    appId: "1:925970610855:web:60529e3b5cb1f67af27982",
    measurementId: "G-7XYG50Y524",
};

const provider = new firebase.auth.GoogleAuthProvider();

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
