export default (state = [], action) => {
    switch (action.type) {
        case "SET_HISTORY":
            return action.payload;
        case "ADD_TO_HISTORY":
            return [...state, action.payload];
        case "CLEAR_HISTORY":
            return [];
        case "MODIFY_HISTORY":
            console.log("modified");
            const { newBookmarked, index } = action.payload;
            const newHistory = state.map((item, i) => {
                if (i === index) {
                    item.bookmarked = newBookmarked;
                }
                return item;
            });
            return newHistory;

        case "SET_FLAGGED":
            console.log("modified");
            const newHistory2 = state.map((item, i) => {
                if (i === action.payload.index) {
                    item.flagged = true;
                }
                return item;
            });
            return newHistory2;
        case "POP_LAST":
            var arr = state;
            arr.pop();
            return arr;
        default:
            return state;
    }
};
