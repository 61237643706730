import { connect } from "react-redux";

import React from "react";
import Grid from "@material-ui/core/Grid";

import "../styles/chat.css";

class ChatHistory extends React.Component {
    getMessages() {
        let messages = [];
        const historyLength = this.props.history.length;
        for (let i = historyLength - 1; i >= 0; i--) {
            messages.push();
        }
        return messages;
    }

    render() {
        const messages = this.props.history;

        return (
            <Grid container direction="column">
                {messages.length > 0 ? (
                    <div />
                ) : (
                    <Grid
                        item
                        className="starter-text"
                        align="center"
                        style={{ marginTop: "50vh" }}
                    >
                        You didn't ask anything.
                    </Grid>
                )}

                {messages.map((m, index) => (
                    <div
                        key={index}
                        style={{ minWidth: "20%" }}
                        className={
                            m.sender === "me"
                                ? "message-me"
                                : m.hintFlag
                                ? "message-hint"
                                : "message"
                        }
                        onClick={
                            m.hintFlag
                                ? () => this.handleHintClicked(m.hint)
                                : undefined
                        }
                    >
                        {
                            <span className="message-text">
                                {m.message === "failure"
                                    ? "We're having some troubles. Please try asking again"
                                    : m.motivation
                                    ? `${m.motivation} ${m.message}`
                                    : `${m.message}`}
                            </span>
                        }

                        <div>
                            {m.image ? (
                                <img
                                    src={m.image}
                                    alt={m.message}
                                    className="message-img"
                                />
                            ) : null}
                        </div>
                    </div>
                ))}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        history: state.history,
        username: state.accountID,
        case: state.case,
        accountID: state.user.uid,
    };
};

export default connect(mapStateToProps, {})(ChatHistory);
