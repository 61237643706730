import React from "react";
import Tree from "react-d3-tree";
import { Popover, Typography, Card, Grid } from "@material-ui/core";
import QuestionView from "./QuestionView";
import { connect } from "react-redux";

import "../styles/tree.css";

class TreeView extends React.Component {
    state = {
        anchorEl: null,
        userQuestions: [],
        idealQuestions: [],
    };

    setAnchorEl = (anchorEl) => {
        this.setState({
            anchorEl: anchorEl,
        });
    };

    handleClose = () => {
        this.setAnchorEl(null);
    };

    handleNodeClick(data, event) {
        console.log(data, event.currentTarget);
        console.log(data.idealQuestions[0]);
        if (data.idealQuestions[0].length === 0) return;
        this.setPopoverContent(data);
        this.setAnchorEl(event.currentTarget);
    }
    setPopoverContent = ({ idealQuestions, userQuestions }) => {
        this.setState({
            idealQuestions,
            userQuestions,
        });
    };
    renderPopoverContent() {
        return (
            <QuestionView
                ideal={this.state.idealQuestions}
                user={this.state.userQuestions}
            />
        );
    }

    componentDidMount() {
        console.log(this.props.treeData);
        // const newTree = this.props.
    }
    render() {
        const open = Boolean(this.state.anchorEl);
        const id = open ? "simple-popover" : undefined;

        const svgShape = {
            shape: "rect",
            shapeProps: {
                width: 250,
                height: 100,
                y: -10,
                x: -125,

                fill: "#ddd",
            },
        };

        const textShape = {
            textAnchor: "middle",
            transform: "scale(1.5) translate(0 25)",
        };
        return (
            <>
                <Card
                    style={{
                        position: "absolute",
                        width: "15em",
                        padding: "1em",
                    }}
                >
                    <Typography
                        style={{ fontFamily: "Montserrat", fontWeight: "600" }}
                        align="center"
                    >
                        LEGEND
                    </Typography>

                    <svg
                        width="20"
                        height="20"
                        style={{ float: "left", marginRight: "1em" }}
                    >
                        <rect
                            width="20"
                            height="20"
                            style={{
                                fill: "#dfd",
                                strokeWidth: 1,
                                stroke: "#ddd",
                            }}
                        />
                    </svg>
                    <Typography style={{ fontFamily: "Montserrat" }}>
                        Aspects Analysed
                    </Typography>
                    <svg
                        width="20"
                        height="20"
                        style={{ float: "left", marginRight: "1em" }}
                    >
                        <rect
                            width="20"
                            height="20"
                            style={{
                                fill: "#fdd",
                                strokeWidth: 1,
                                stroke: "#ddd",
                            }}
                        />
                    </svg>
                    <Typography style={{ fontFamily: "Montserrat" }}>
                        Aspects Missed
                    </Typography>
                    <svg
                        width="20"
                        height="20"
                        style={{ float: "left", marginRight: "1em" }}
                    >
                        <rect
                            width="20"
                            height="20"
                            style={{
                                fill: "#ddd",
                                strokeWidth: 1,
                                stroke: "#ddd",
                            }}
                        />
                    </svg>
                    <Typography style={{ fontFamily: "Montserrat" }}>
                        Container Node
                    </Typography>

                    <Typography
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: ".75em",
                            marginTop: "1em",
                        }}
                        align="center"
                    >
                        Click and drag to navigate
                    </Typography>
                    <Typography
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: ".75em",
                        }}
                        align="center"
                    >
                        Use the scroll wheel to zoom
                    </Typography>

                    <Typography
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: ".75em",
                            marginTop: "1em",
                            fontWeight: "600",
                        }}
                        align="center"
                    >
                        Tip: Try clicking the Non-Grey Tree Nodes!
                    </Typography>
                    
                </Card>
                {/* <Tree /> will fill width/height of its container; in this case `#treeWrapper` */}
                <Tree
                    data={this.props.treeData}
                    nodeSvgShape={svgShape}
                    textLayout={textShape}
                    orientation="vertical"
                    zoom=".5"
                    pathFunc="step"
                    depthFactor="300"
                    collapsible={false}
                    translate={{ x: 700, y: 100 }}
                    separation={{ siblings: 2.5, nonSiblings: 2.5 }}
                    onClick={(data, event) => {
                        this.handleNodeClick(data, event);
                    }}
                />
                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <div>{this.renderPopoverContent()}</div>
                </Popover>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { treeData: state.analysisTree };
};

export default connect(mapStateToProps)(TreeView);
