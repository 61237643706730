import React from "react";
import {
    Grid,
    Typography,
    Button,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import history from "../history";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import MailIcon from "@material-ui/icons/Mail";
import "../styles/footer.css";

export default function Footer() {
    const theme = useTheme();

    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    return (
        <div
            style={{
                height: "100%",
                backgroundColor: "#000",
                color: "#fff",
                fontFamily: "montserrat",
            }}
        >
            <Grid container direction="column" alignItems="center">
                <Grid
                    container
                    direction={matchesXS ? "column" : "row"}
                    justify="space-between"
                    alignItems={matchesXS ? "center" : null}
                    style={{ marginTop: "1em" }}
                >
                    <Grid
                        item
                        container
                        direction="column"
                        style={
                            matchesXS
                                ? {
                                      marginLeft: 0,
                                      fontSize: "2em",
                                      marginTop: "1em",
                                      width: "5em",
                                  }
                                : {
                                      marginLeft: "2em",
                                      fontSize: "2em",
                                      marginTop: "1em",
                                      width: "5em",
                                  }
                        }
                    >
                        <Grid item>
                            <span style={{ fontWeight: 600 }}>case</span>
                            cade.
                        </Grid>
                        <Grid item style={{ marginTop: "-.75em" }}>
                            <span style={{ fontSize: ".45em" }}>
                                Think. Practice. Learn.
                            </span>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        container
                        direction="column"
                        alignItems="center"
                        style={{ width: "10em" }}
                    >
                        <Grid
                            item
                            style={{ fontWeight: 600, marginTop: "1em" }}
                        >
                            Quick Links
                        </Grid>
                        <Grid
                            item
                            style={{ marginTop: ".5em" }}
                            className="footerLink"
                            onClick={() => history.push("/")}
                        >
                            Home
                        </Grid>
                        <Grid
                            item
                            style={{ marginTop: ".5em" }}
                            className="footerLink"
                            onClick={() => history.push("/about")}
                        >
                            About
                        </Grid>
                        <Grid
                            item
                            style={{ marginTop: ".5em" }}
                            className="footerLink"
                            component="a"
                            href="https://blog.casecade.in/"
                        >
                            Blog
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        container
                        direction="column"
                        style={{ width: "15em", marginTop: "2.5em" }}
                    >
                        <Button
                            variant="contained"
                            style={{
                                borderRadius: "20px",
                                backgroundColor: "#ff4f5a",
                                marginLeft: "3em",
                                marginRight: "3em",
                            }}
                            href="https://pages.razorpay.com/pl_FHNaQi8aTR5fvX/view"
                            rel="noreferrer noopener"
                            target="__blank"
                        >
                            <Typography
                                variant="h2"
                                style={{
                                    fontFamily: "Montserrat",
                                    fontWeight: 600,
                                    textTransform: "none",
                                    color: "#fff",
                                    fontSize: "1em",
                                }}
                            >
                                Support Us!
                            </Typography>
                        </Button>
                        <Grid
                            item
                            style={{ marginLeft: "auto", marginRight: "auto" }}
                        >
                            <Button
                                href="https://www.linkedin.com/company/casecade/"
                                rel="noreferrer noopener"
                                target="__blank"
                                size="small"
                                style={{ color: "#fff", width: ".5em" }}
                            >
                                <LinkedInIcon />
                            </Button>
                            <Button
                                href="mailto:info@casecade.in"
                                rel="noreferrer noopener"
                                target="__blank"
                                size="small"
                                style={{
                                    color: "#fff",
                                    padding: 0,
                                    width: ".5em",
                                }}
                            >
                                <MailIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    style={{
                        marginTop: ".5em",
                        marginRight: "auto",
                        marginLeft: "1em",
                    }}
                >
                    © Casecade 2020
                </Grid>
            </Grid>
        </div>
    );
}
