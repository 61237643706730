import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import { connect } from "react-redux";

import "../styles/expansion-panel.css";
import ProblemStatement from "./ProblemStatement";
import KnowledgeBank from "./KnowledgeBank";

class RightPanel extends React.Component {
	// DO NOT delete below state
	// Used for managing expansion panels
	state = {
		expanded: true,
	};
	// DO NOT delte above state

	// Handle expansion panel change
	handleChange = () => () => {
		this.setState({ expanded: !this.state.expanded });
	};

	render() {
		console.log(this.props.questions);
		return (
			<div style={{ margin: "2em" }}>
				<h3>You are consulting for {this.props.title} </h3>
				<ExpansionPanel
					expanded={this.state.expanded}
					onChange={this.handleChange()}
					style={{ overflow: "auto" }}>
					<ExpansionPanelSummary
						aria-controls="panel1d-content"
						id="panel1d-header">
						<Typography>Problem Statement</Typography>
						<div style={{ marginLeft: "auto" }}>
							{this.state.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</div>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid container>
							<Grid item className="question-container">
								<ProblemStatement />
							</Grid>
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
				<ExpansionPanel expanded>
					<ExpansionPanelSummary
						aria-controls="panel2d-content"
						id="panel2d-header"
						className="knowledge-bank-header">
						<Typography>Knowledge Bank</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid
							container
							direction="column"
							className="knowledge-bank-container">
							<KnowledgeBank />
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return { questions: state.questions, title: state.caseTitle };
};

export default connect(mapStateToProps)(RightPanel);
