import React from "react";
import { connect } from "react-redux";

import BackEnd from "../apis/caseBackEnd";
import { setProblemStatement, setQuestions, setTime } from "../actions";
import history from "../history";

import "../styles/case.css";
class ProblemStatement extends React.Component {
	fetchProblemStatement = async () => {
		return await BackEnd.post("/problem", {
			case: this.props.case,
			accountID: this.props.user.uid,
			refreshFlag: false,
		});
	};

	componentDidMount() {
		console.log("Mounted!");
		this.fetchProblemStatement()
			.then((response) => {
				const data = response.data;
				console.log(data);
				setTime(data.t);
			})
			.catch((e) => console.log(e));
	}

	render() {
		// if (!this.props.case) {
		//     return <div>Redirecting...</div>;
		// }
		const problemStatement = this.props.problemStatement;

		return (
			<div className="questionContainer">
				<div className="problemStatement">{problemStatement}</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		accountID: state.accountID,
		case: state.case,
		problemStatement: state.problemStatement,
		questions: state.questions,
		user: state.user,
	};
};

export default connect(mapStateToProps, {
	setProblemStatement,
	setQuestions,
	setTime,
})(ProblemStatement);
