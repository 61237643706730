export default (state=[], action) => {
    switch(action.type){
        case 'SET_ANSWER':
            const {answer, index} = action.payload
            const newState= state;
            newState[index] = answer
            return newState
        case 'CLEAR_ANSWERS':
            return []
        default:
            return state
    }
}