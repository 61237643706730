import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import { DialogActions } from "@material-ui/core";

const emails = ["username@gmail.com", "user02@gmail.com"];
const useStyles = makeStyles({
	root: {},
	avatar: {
		backgroundColor: blue[100],
		color: blue[600],
	},

	title: {
		fontSize: "1.25em",
		textAlign: "center",
		fontFamily: "Montserrat",
		fontWeight: 600,
		marginTop: "2em",
		marginBottom: "2em",
	},

	listHeader: {
		fontFamily: "Montserrat",
		fontWeight: 600,
		fontSize: "1em",
		marginLeft: "2em",
		marginRight: "2em",
	},

	listContent: {
		fontFamily: "Montserrat",
		fontWeight: 400,
		fontSize: "1em",
		marginLeft: "2em",
		marginRight: "2em",
	},
	link: {
		textDecoration: "none",
		color: "#007aff",
		transitionDuration: ".2s",
		"&:hover": {
			color: "#229cff",
			transitionDuration: ".2s",
		},
	},
	button: {
		marginTop: "2em",
		marginRight: "1em",
	},
	red: { color: "#e31", float: "left", marginRight: ".5em" },
	green: { color: "#5a2", float: "left", marginRight: ".5em" },
});

export default function SimpleDialog(props) {
	const classes = useStyles();
	const { onClose, open } = props;

	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby="simple-dialog-title"
			open={open}
			className={classes.root}>
			<Typography variant="h5" className={classes.title}>
				Important Instructions
			</Typography>
			<Typography variant="h6" className={classes.listHeader}>
				Write in concise, but complete sentences.
			</Typography>
			<Typography variant="h6" className={classes.listContent}>
				<CheckIcon className={classes.green} />
				What is the profit margin?
			</Typography>

			<Typography variant="h6" className={classes.listContent}>
				<ClearIcon className={classes.red} />
				Can you let me know how much profit is made per product?
			</Typography>

			<Typography
				variant="h6"
				className={classes.listHeader}
				style={{ marginTop: "1em" }}>
				Restrict yourself to one idea per question.
			</Typography>
			<Typography variant="h6" className={classes.listContent}>
				<CheckIcon className={classes.green} />
				What is the profit margin of the product?
			</Typography>
			<Typography variant="h6" className={classes.listContent}>
				<CheckIcon className={classes.green} />
				What are the fixed costs for the product?
			</Typography>
			<Typography variant="h6" className={classes.listContent}>
				<ClearIcon className={classes.red} />
				What is the profit margin and fixed costs for the product?
			</Typography>

			<Typography
				variant="h6"
				className={classes.listHeader}
				style={{ marginTop: "1em" }}>
				Always specify context and avoid counter-questions
			</Typography>
			<Typography variant="h6" className={classes.listContent}>
				<CheckIcon className={classes.green} />
				What are the fixed costs?
			</Typography>
			<Typography variant="h6" className={classes.listContent}>
				<CheckIcon className={classes.green} />
				What has been the change in fixed costs?
			</Typography>
			<Typography variant="h6" className={classes.listContent}>
				<ClearIcon className={classes.red} />
				What has been the change in them?
			</Typography>

			<Typography
				variant="h6"
				className={classes.listHeader}
				style={{ marginTop: "1em" }}>
				Please ensure you type correct spellings
			</Typography>
			<Typography variant="h6" className={classes.listContent}>
				<CheckIcon className={classes.green} />
				What are the variable costs?
			</Typography>
			<Typography variant="h6" className={classes.listContent}>
				<ClearIcon className={classes.red} />
				What are the <span style={{ color: "#e31" }}>vairable</span> costs?
			</Typography>

			<Typography variant="h6" className={classes.listContent} style={{marginTop: '1em'}}>
				<span style={{fontWeight: 600}}>Tip: </span>Type "I'm stuck" in the chat box for a hint
			</Typography> 

			<Typography
				variant="h6"
				className={classes.listHeader}
				style={{ marginTop: "1em" }}>
				For learning about frameworks refer to the{" "}
				<a href="https://blog.casecade.in/" className={classes.link}>
					Casecade blog
				</a>
				!
			</Typography>

			
			
			<DialogActions>
				<Button onClick={handleClose} className={classes.button}>
					Continue
				</Button>
			</DialogActions>
		</Dialog>
	);
}
