export default (state = [], action) => {
    if (action.type === "ADD_TO_KNOWLEDGE") {
        const { message, index, image } = action.payload;
        return [...state, { message: message, index: index, image: image }];
    } else if (action.type === "REMOVE_FROM_KNOWLEDGE") {
        const index = action.payload;
        return state.filter((item) => {
            return item.index !== index;
        });
    }
    return state;
};
