import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../assets/logoWhite.png";
import BackEnd from "../apis/caseBackEnd";
import Button from "@material-ui/core/Button";
import UserMenu from "./userMenu";

import {
	addCaseHeader,
	setUsername,
	setUser,
	clearStore,
	resetUser,
	clearHeaders,
	setCaseTitle,
	selectCase,
	setProblemStatement,
	setQuestions,
	setHistory,
	addToHistory,
	setTime,
	setAnalysisTree,
	setMetrics,
	setAnswerAnalysis,
	setAnswers,
} from "../actions";
import history from "../history";
import { auth } from "../firebase";
import CaseHeader from "./CaseHeader";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import "../styles/board.css";
import Footer from "./Footer";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function ContinuePopup({ open, handleClose, startNewCase, continueCase }) {
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">
					{"You have a previous attempt available"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						You can continue where you left off by clicking "Continue".
						Alternatively, you can start over. If you click "Start Over",
						previous history will be lost.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => continueCase()} color="primary" autoFocus>
						Continue
					</Button>
					<Button onClick={() => startNewCase()} color="primary">
						Start over
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

function CompletedPopup({ open, handleClose, startNewCase, goToAnalysis }) {
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">
					{"You have completed this case previously"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						You can view your previous analysis by clicking "View Previous".
						Alternatively, you can start over. If you click "Start Over",
						previous history will be lost.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => goToAnalysis()} color="primary" autoFocus>
						View Previous
					</Button>
					<Button onClick={() => startNewCase()} color="primary">
						Start over
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

class Board extends Component {
	// TODO: change username to FIREBASE AUTH

	// DO NOT delete below state.
	// Needed for controlling username input
	state = {
		user: {},
		error: false,
		partialPopUp: false,
		completedPopUp: false,
		header: "",
		id: "",
	};

	// DO NOT delete above state

	fetchCases = async () => {
		console.log(this.props.user);
		return await BackEnd.post("/", {
			accountID: this.props.user.uid,
		});
	};

	setUser = (id) => {
		this.props.setUsername(id);
	};

	startNewCase = async () => {
		await this.props.clearStore();
		await this.props.selectCase(this.state.id);
		this.props.setCaseTitle(this.state.header);
		const { setProblemStatement, setQuestions } = this.props;
		setProblemStatement("Loading problem");
		history.push(`/case/${this.state.id}`);
		// if(this.props.case===this.props.match.params.id) return
		this.fetchProblemStatement(true)
			.then((response) => {
				const data = response.data;
				setProblemStatement(data["caseStatement"]);
				setQuestions(data["questions"]);
				setTime(0);
			})
			.catch((e) => console.log(e));
	};

	goToAnalysis = async () => {
		await this.props.clearStore();
		await this.props.selectCase(this.state.id);
		this.props.setCaseTitle(this.state.header);
		this.handleAnswer();
	};

	fetchAnswer = async () => {
		return await BackEnd.post("/answer", {
			case: this.props.case,
			accountID: this.props.user.uid,
		});
	};

	handleAnswer() {
		this.fetchAnswer()
			.then((response) => {
				const data = response.data;
				console.log(data);
				this.setState({
					answer: data["response"],
				});
				this.props.setHistory(data["history"]);
				this.props.setAnalysisTree(data["tree"]);
				this.props.setMetrics(data["metrics"]);
				this.props.setQuestions(data["questions"]);
				this.props.setAnswers(data["answer"]);
				this.props.setAnswerAnalysis({
					ideal: data["idealAnswers"],
					qType: data["response"],
					user: data["answer"],
					questions: data["questions"].map(question=>{
						return question
					}),
				});
				history.push("/analysis");
			})
			.catch((e) => {});
	}

	continueCase = async () => {
		await this.props.clearStore();
		await this.props.selectCase(this.state.id);
		this.props.setCaseTitle(this.state.header);
		const {
			setProblemStatement,
			setQuestions,
			setHistory,
			setTime,
		} = this.props;
		setProblemStatement("Loading problem");

		// if(this.props.case===this.props.match.params.id) return
		this.fetchProblemStatement(false)
			.then((response) => {
				const data = response.data;
				setProblemStatement(data["caseStatement"]);
				setQuestions(data["questions"]);
				const t = data.time;
				setTime(t);
				// data["history"].map((his) => {
				// 	addToHistory(his);
				// });
				setHistory(data["history"]);
				history.push(`/case/${this.state.id}`);
			})
			.catch((e) => console.log(e));
	};
	handleClick = async (id, header, s) => {
		if (s === "not-completed") {
			this.setState({ header: header, partialPopUp: true, id: id });
			return;
		} else if (s === "completed") {
			this.setState({ header: header, completedPopUp: true, id: id });
			return;
		} else {
			this.setState({ header: header, id: id });
			this.startNewCase();
		}
		// await this.props.clearStore();
		// await this.props.selectCase(id);
		// this.props.setCaseTitle(header);
		// const { setProblemStatement, setQuestions } = this.props;
		// setProblemStatement("Loading problem");
		// history.push(`/case/${id}`);
		// // if(this.props.case===this.props.match.params.id) return
		// this.fetchProblemStatement()
		// 	.then((response) => {
		// 		const data = response.data;
		// 		setProblemStatement(data["caseStatement"]);
		// 		setQuestions(data["questions"]);
		// 	})
		// 	.catch((e) => console.log(e));
	};

	fetchProblemStatement = async (refresh) => {
		return await BackEnd.post("/problem", {
			case: this.props.case,
			accountID: this.props.user.uid,
			refreshFlag: refresh,
		});
	};

	async componentDidMount() {
		auth.onAuthStateChanged((user) => {
			if (!user) {
				setTimeout(function () {
					history.push("/login");
				}, 3000);
			}
		});
		if (this.props.headers.length !== 0) {
			this.props.clearHeaders();
		}

		this.fetchCases()
			.then((res) => {
				console.log(res);
				const headers = res.data.headers;
				// // const headers=["one", "two"]
				headers.map((header) => this.props.addCaseHeader(header));
			})
			.catch((e) => {
				console.log(e);
				this.setState({ error: true });
			});
	}

	renderList() {
		// console.log(this.props.headers)
		let list = [];
		this.props.headers.forEach((header, index) => {
			if (header.case_id !== "energy-drink-company-strike")
				list.push(
					<CaseHeader
						header={header}
						key={index}
						onClick={(id, header, s) => this.handleClick(id, header, s)}
					/>
				);
		});
		return list;
	}

	handleChange = (event) => {
		this.setState({
			username: event.target.value,
		});
		this.props.setUsername(event.target.value);
	};

	signOutOfApp = async () => {
		auth.signOut();
		await this.props.resetUser();
		this.props.clearStore();
	};
	partialPopUpClosed = () => {
		this.setState({ partialPopUp: false });
	};
	completedPopUpClosed = () => {
		this.setState({ completedPopUp: false });
	};
	render() {
		if (!this.props.user.uid) {
			return (
				<Grid container direction="column" className="boardBackground">
					<Grid item container className="boardHeader">
						<Grid item>
							<Button component={Link} to="/" disableRipple>
								<img src={logo} alt="Company logo" className="boardBrand" />
							</Button>
						</Grid>
						<Grid
							item
							style={{
								marginLeft: "auto",
								marginRight: "2em",
								marginTop: "1em",
							}}>
							<Typography variant="h3" id="logged-in" align="center">
								You're not logged in.
							</Typography>
						</Grid>
					</Grid>
					<Grid item container justify="center" style={{ marginTop: "10em" }}>
						<Typography id="topText">Redirecting to Login Page...</Typography>
					</Grid>
					<Grid item container justify="center" style={{ marginTop: "1em" }}>
						<CircularProgress />
					</Grid>
				</Grid>
			);
		}
		if (this.props.headers.length === 0 && !this.state.error) {
			return (
				<Grid container direction="column" className="boardBackground">
					<Grid item container className="boardHeader">
						<Grid item>
							<Button component={Link} to="/" disableRipple>
								<img src={logo} alt="Company logo" className="boardBrand" />
							</Button>
						</Grid>
						<Grid
							item
							style={{
								marginLeft: "auto",
								marginRight: "2em",
								marginTop: "1em",
							}}>
							<Typography variant="h3" id="logged-in" align="center">
								You're logged in as
							</Typography>
							<UserMenu
								name={this.props.user.name}
								onClickSignOut={this.signOutOfApp}
							/>
						</Grid>
					</Grid>
					<Grid item container justify="center" style={{ marginTop: "10em" }}>
						<Typography id="topText">
							Please wait while we load content...
						</Typography>
					</Grid>
					<Grid
						item
						container
						justify="center"
						style={{ marginTop: "1em", marginBottom: "30em" }}>
						<CircularProgress />
					</Grid>
					<Footer />
				</Grid>
			);
		} else if (this.state.error) {
			return (
				<Grid container direction="column" className="boardBackground">
					<Grid item container className="boardHeader">
						<Grid item>
							<Button component={Link} to="/" disableRipple>
								<img src={logo} alt="Company logo" className="boardBrand" />
							</Button>
						</Grid>
						<Grid
							item
							style={{
								marginLeft: "auto",
								marginRight: "2em",
								marginTop: "1em",
							}}>
							<Typography variant="h3" id="logged-in" align="center">
								You're logged in as
							</Typography>
							<UserMenu
								name={this.props.user.name}
								onClickSignOut={this.signOutOfApp}
							/>
						</Grid>
					</Grid>
					<Grid item container justify="center" style={{ marginTop: "10em" }}>
						<Typography id="topText">
							Oh no! We're unable to reach our servers at this time! Please try
							again!
						</Typography>
					</Grid>
					<Grid
						item
						container
						justify="center"
						style={{ marginTop: "1em", marginBottom: "30em" }}></Grid>
					<Footer />
				</Grid>
			);
		}
		// console.log(this.props.headers)
		else {
			return (
				<Grid container direction="column" className="boardBackground">
					<Grid item container className="boardHeader">
						<Grid item>
							<Button component={Link} to="/" disableRipple>
								<img src={logo} alt="Company logo" className="boardBrand" />
							</Button>
						</Grid>

						<Grid
							item
							style={{
								marginLeft: "auto",
								marginRight: "2em",
								marginTop: "1em",
							}}>
							<Typography variant="h3" id="logged-in" align="center">
								You're logged in as
							</Typography>
							<UserMenu
								name={this.props.user.name}
								onClickSignOut={this.signOutOfApp}
							/>
						</Grid>
					</Grid>

					<Grid item container direction="column" alignItems="center">
						<Grid item container justify="center" style={{ marginTop: "5em" }}>
							<Typography id="topText" style={{ fontSize: "2em" }}>
								Hello, {this.props.user.name}!
							</Typography>
						</Grid>

						<Grid item container justify="center">
							<Typography id="topText">
								What would you like to consult for today?
							</Typography>
						</Grid>

						<Grid item container justify="center">
							<Typography id="topText">Click a card to begin</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						container
						direction="column"
						alignItems="center"
						style={{ marginBottom: "2em" }}>
						{this.renderList()}
					</Grid>
					<Grid
						item
						container
						justify="center"
						style={{ marginBottom: "2em", marginTop: "8em" }}>
						<Typography id="topText">
							More cases are being added regularly!
						</Typography>
					</Grid>

					<Footer />
					<ContinuePopup
						open={this.state.partialPopUp}
						handleClose={this.partialPopUpClosed}
						startNewCase={this.startNewCase}
						continueCase={this.continueCase}
					/>
					<CompletedPopup
						open={this.state.completedPopUp}
						handleClose={this.completedPopUpClosed}
						startNewCase={this.startNewCase}
						goToAnalysis={this.goToAnalysis}
					/>
				</Grid>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		headers: state.headers,
		case: state.case,
		username: state.accountID,
		user: state.user,
	};
};

export default connect(mapStateToProps, {
	addCaseHeader,
	clearStore,
	setUsername,
	setUser,
	resetUser,
	clearHeaders,
	selectCase,
	setCaseTitle,
	setProblemStatement,
	setQuestions,
	setHistory,
	addToHistory,
	setTime,
	setAnalysisTree,
	setMetrics,
	setAnswerAnalysis,
	setAnswers,
})(Board);
