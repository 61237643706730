import React from "react";
import { Typography, Button, Grid } from "@material-ui/core";
import history from "../history";
import Header from "./Header";
import Footer from "./Footer";
export default function NotForMobileScreen() {
	return (
		<>
			<Header user={{ uid: null }} />
			<Grid
				container
				style={{ padding: "2em", marginTop: "8em", marginBottom: "8em" }}
				justify="center"
				alignItems="center">
				<Typography
					variant="h1"
					align="center"
					style={{ fontSize: "2em", fontWeight: "bold" }}>
					Oops! :(
				</Typography>
				<Typography variant="h2" align="center" style={{ marginTop: "3em" }}>
					We're at an early stage of development and our product is not yet
					optimized for mobile! Please login through a desktop while we try to
					get a mobile experience to you soon!
				</Typography>
				<Grid item style={{}}>
					<Button
						style={{ marginTop: "2em" }}
						onClick={() => history.push("/")}>
						Back to home
					</Button>
				</Grid>
			</Grid>
			<Footer />
		</>
	);
}
