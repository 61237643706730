import React, { useEffect } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { auth } from "../firebase";
import firebase from "firebase";
import { connect } from "react-redux";
import { setUsername, setUser, clearHeaders } from "../actions";
import {
	Grid,
	Card,
	Typography,
	Tabs,
	Tab,
	Button,
	useMediaQuery,
	useTheme,
	Hidden,
} from "@material-ui/core";
import logo from "../assets/logoWhite.png";
import "../styles/login.css";
import { Link } from "react-router-dom";
import history from "../history";
import { isMobile } from "react-device-detect";
import NotForMobileScreen from "./NotForMobileScreen";

const uiConfig = {
	// Popup signin flow rather than redirect flow.
	signInFlow: "popup",
	// Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.

	// We will display Google and Facebook as auth providers.
	signInOptions: [
		firebase.auth.GoogleAuthProvider.PROVIDER_ID,
		firebase.auth.EmailAuthProvider.PROVIDER_ID,
	],
	callbacks: {
		signInSuccess: function () {
			return false;
		},
	},
};

function Login(props) {
	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			if (user) {
				initializeUser(user);
			}
		});
	}, []);

	const initializeUser = async (user) => {
		await props.setUser(user.displayName, user.email, user.uid);
		props.clearHeaders();
		console.log(props.user);
		history.push("/board");
	};

	const theme = useTheme();
	const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

	// if (isMobile) return <NotForMobileScreen />;
	return (
		<Grid
			container
			direction="column"
			className="background"
			style={{ height: "100vh" }}>
			<Grid item container style={{ marginTop: "1em" }}>
				<Grid item>
					<Button component={Link} to="/">
						<img src={logo} alt="Company logo" className="brand" />
					</Button>
				</Grid>

				<Grid item style={{ marginLeft: "auto" }}>
					<Tabs id="landingTabContainer">
						<Hidden xsDown>
							<Tab disableRipple selected label={"Sign In"} id="loginTabs" />
						</Hidden>
						<Tab disableRipple label={"About"} id="loginTabs" href="/about" />
						<Tab
							disableRipple
							label={"Blog"}
							id="loginTabs"
							href="https://blog.casecade.in/"
						/>
					</Tabs>
				</Grid>
			</Grid>
			<Grid
				container
				style={{ marginTop: "10em" }}
				direction={matchesSM ? "column" : "row"}>
				<Grid item className={matchesSM ? "heroContainerMob" : "heroContainer"}>
					<Typography variant="h3" align={matchesSM ? "center" : "inherit"}>
						Jump-start your consulting career.
					</Typography>
					<Typography variant="h4" align={matchesSM ? "center" : "inherit"}>
						Sign in to view and solve from a rapidly-expanding list of Case
						Studies across different types
					</Typography>
				</Grid>
				<Grid item className={matchesSM ? "cardContainerMob" : "cardContainer"}>
					<Card style={{ padding: "2em" }} className="loginContainer">
						<Typography variant="h2" align="center">
							Sign in to use <span className="case">case</span>
							cade.
						</Typography>

						<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
					</Card>
				</Grid>
			</Grid>
		</Grid>
		// <div>
		//     <h1>Sign In</h1>
		//     <p>Please sign in to use the app</p>

		//     <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
		// </div>
	);
}
const mapStateToProps = (state) => {
	return {
		user: state.user,
	};
};
export default connect(mapStateToProps, { setUsername, setUser, clearHeaders })(
	Login
);
